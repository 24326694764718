import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { Configuration } from '../generated';
import { getInternalUrl, getPublicUrl, isInternal } from '../helper/environment';
import { getAccessToken } from '../helper/authHeader';

export const getApiBasePath = () => {
    return isInternal() ? getInternalUrl(): getPublicUrl();
};

// The base configuration for all services
const config = new Configuration({
    basePath: getApiBasePath(),
    baseOptions: {
        // `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
        xsrfCookieName: 'XSRF-TOKEN', // default
        // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
        xsrfHeaderName: 'X-XSRF-TOKEN', // default
        withCredentials: 'include',
        headers: {
            'accept': 'application/vnd.regcomp-uam.api.v1+json',
        },
    },
});

export default config;

export const onFullFilledResponse = (response: AxiosResponse) => {
    // Any status code that lies within the range of 200 to 302 triggers this function
    return response;
};

export const onRejectedResponse = (error: AxiosError) => {
    return Promise.reject(error);
};

// API response handling - https://github.com/axios/axios#interceptors
axios.interceptors.response.use(onFullFilledResponse, onRejectedResponse);

export const onFullFilledRequest = (config: InternalAxiosRequestConfig) => {
    const authorizationHeader = getAccessToken();

    if (authorizationHeader) {
        config.headers.set('Authorization', authorizationHeader);
    }
    return config;
};

export const onRejectedRequest = (error: AxiosError) => {
    return Promise.reject(error);
};

// Add a request interceptor
axios.interceptors.request.use(onFullFilledRequest, onRejectedRequest);
