import { Link } from 'atupri-component-library/lib/primitives';
import styled from '@emotion/styled';
import { ColorsInterface, IconInterface } from 'atupri-component-library/lib/types';
import { colors } from 'atupri-component-library/lib/core/styles/theme/default';

const LinkButton = ({ onClick, text, iconColor = 'primary', ...rest }: LinkButtonProps) => {
    const LinkWithButtonCursor = styled(Link)`
        cursor: pointer;
        i {
            color: ${colors[iconColor]};
        }
    `;

    return <LinkWithButtonCursor linkText={text} link={{ to: '' }} color="primary" onClick={onClick} {...rest} />;
};
type LinkButtonProps = {
    onClick: () => void;
    text: string;
    iconColor?: ColorsInterface;
    iconAfter?: IconInterface;
    iconBefore?: IconInterface;
};

export default LinkButton;
