import { AxiosResponse } from 'axios';
import { ExceptionResponse } from '../generated';
import store from '../redux/store';
import { setFormState, setFormStateErrorWithMessage, setLoading } from '../redux/application.slide';
import { FormState } from '../model/FormState';

/* eslint-disable no-console */
export function logger(tag: string, content: any, isError?: boolean) {
    if (localStorage.getItem('showLogs')) {
        console.group(
            `%c${tag}`,
            'color: black; background-color: yellow; padding: 2px 5px; border-radius: 2px; margin: 0;'
        );
        if (isError) {
            if (Array.isArray(content)) {
                content.forEach((item: any) => {
                    console.error(item);
                });
            } else {
                console.error(content);
            }
        } else {
            if (Array.isArray(content)) {
                content.forEach((item: any) => {
                    console.info(item);
                });
            } else {
                console.info(content);
            }
        }
        console.groupEnd();
    }
}

export const callApi = <T>(
    logId: string,
    promise: Promise<AxiosResponse<T>>,
    okFn: (data: T) => void,
    withLoadingIcon = true,
    errorFn?: (httpStatus: number, data: ExceptionResponse) => void
) => {
    logger('callApi: ' + logId, 'before calling ');

    if (withLoadingIcon) {
        store.dispatch(setLoading(true));
    }

    store.dispatch(setFormState(FormState.START));
    promise
        .then((response) => {
            store.dispatch(setFormState(FormState.START));

            logger('callApi: ' + logId, ['Response Successfully.']);

            okFn(response.data);
        })
        .catch((error) => {
            // Each screen have a place to display general errors (unrecoverable)
            // and also a place to display errors produced from the user input (business logic errors,
            // recoverable by the user)
            const httpsStatus = error?.response?.status || -1;
            if (httpsStatus >= 400 && httpsStatus <= 499) {
                const data = error.response.data;

                if (data?.businessError ?? false) {
                    const apiBusErrorData = error.response.data as ExceptionResponse;
                    if (errorFn) {
                        errorFn(error.response.status, apiBusErrorData);
                    } else {
                        // debug msg only show up when not in Production
                        let debugMsg = apiBusErrorData?.debugMessage ?? '';
                        if (debugMsg.length > 0) {
                            debugMsg = ' (' + debugMsg + ')';
                        }
                        const msgToShow = (apiBusErrorData.message ?? 'Error');
                        store.dispatch(setFormStateErrorWithMessage(msgToShow));
                    }
                } else {
                    if (errorFn) {
                        errorFn(error.response.status, data);
                    }

                    logger('callApi: ' + logId, ['Response with error', error], true);
                    store.dispatch(setFormState(FormState.ERROR_GENERIC));
                }
            } else {
                logger('callApi: ' + logId, ['Response with error', error], true);
                store.dispatch(setFormState(FormState.ERROR_GENERIC));
            }
        })
        .finally(() => {
            logger('callApi: ' + logId, 'end calling');
            if (withLoadingIcon) {
                store.dispatch(setLoading(false));
            }
        });
};
