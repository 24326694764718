import { Field, FieldInputProps } from 'react-final-form';
import { FieldValidator } from 'final-form';
import { isValid } from 'date-fns';
import { GridItem } from 'atupri-component-library/lib/helpers';
import { Datepicker } from 'atupri-component-library/lib/primitives';

// on change method called when the user uses the keyboard to enter the date
// DatePiker component creates a date from the Local time zone
const defaultChangeHandler = (
	date: Date,
	input: FieldInputProps<any, HTMLElement>
) => {
	if (isValid(date)) {
		input.onChange(date)
		input.onBlur();
	} else {
		input.onChange(null);
	}
};

const GridItemDatepicker = ({
	gridItemSize = { zero: 'full', medium: 6 },
	inputName,
	label,
	onChange = defaultChangeHandler,
	maxDateInFuture,
	minDate,
	startDate,
	tileDisabled,
	validators,
}: GridItemDatepickerProps) => {

	return (
		<GridItem size={gridItemSize}>
			<Field
				label={label}
				name={inputName}
				validate={validators}
				tileDisabled={tileDisabled}
			>
				{({ label, input, meta, tileDisabled }) => (
					<Datepicker
						label={label}
						name={input.name}
						startDate={startDate}
						maxDate={maxDateInFuture}
						minDate={minDate}
                        onChange={(date: any) => onChange ? onChange(date, input) : null}
                        onCalendarClose={input.onBlur}
						errorText={meta.touched && meta.error}
						tileDisabled={tileDisabled}
					/>
				)}
			</Field>
		</GridItem>
	);
};

type GridItemDatepickerProps = {
	gridItemSize?: Object
	inputName: string;
	label: string;
	maxDateInFuture: Date;
	minDate?: Date;
	onChange?: (date: Date, input: FieldInputProps<any, HTMLElement>) => void;
	startDate?: Date;
	tileDisabled?: (date: any) => boolean;
	validators: FieldValidator<any>;
};

export default GridItemDatepicker;
