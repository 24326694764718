import { getAtuprichHost, getSimpleClientLanguage } from '../helper';
import { MinimalHeaderProps } from '@atupri/atupri-component-library';

const headerData = () : MinimalHeaderProps => {
    const language = getSimpleClientLanguage();
    const atuprichHost = getAtuprichHost();

    return {
        logo: {
            imageDefault: {
                src: 'https://a.storyblok.com/f/253863/98x35/f0cb32645a/logo-default.svg',
                alt: 'Atupri Logo',
            },
            imageInverted: {
                src: 'https://a.storyblok.com/f/253863/98x35/2fc018e7ab/logo-inverted.svg',
                alt: 'Atupri Logo inverted',
            },
            link: {
                href: atuprichHost + language,
                title: 'home',
                trackingLabel: 'logo'
            }
        },
        languageSwitcherLinks : [
            {
                label: 'DE',
                href: window.location.pathname + '?lng=de',
                isActive: language === 'de',
                trackingLabel: 'de',
            },
            {
                label: 'FR',
                href: window.location.pathname + '?lng=fr',
                isActive: language === 'fr',
                trackingLabel: 'fr',
            },
            {
                label: 'IT',
                href: window.location.pathname + '?lng=it',
                isActive: language === 'it',
                trackingLabel: 'it',
            },
        ]
    };
};

export default headerData;
