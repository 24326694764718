import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';

import React, { Suspense } from 'react';
import './fonts.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Loader from './components/Loader';
import { Provider } from 'react-redux';
import store from './redux/store';
import { createRoot } from 'react-dom/client';

const domNode = document.getElementById('root');
const root = createRoot(domNode!);
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<Suspense fallback={<Loader variant="cover"/>}>
				<App />
			</Suspense>
		</Provider>
	</React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
