import styled from '@emotion/styled';
import { Grid, GridItem, Spacer, Stack } from 'atupri-component-library/lib/helpers';
import i18next from 'i18next';
import { GridItemInput } from '../../components/FormControls';
import { Button, H2, Icon } from 'atupri-component-library/lib/primitives';
import { Form } from 'react-final-form';
import { passwordCriteriaMetValidator, ValidationState } from './AskPasswordValidationFunctions';
import { useState } from 'react';
import { rem } from 'polished';
import { colors, spaces } from 'atupri-component-library/lib/core/styles/theme/default';
import { MIN_DIGITS, MIN_LENGTH, MIN_LOWERCASE, MIN_SPECIAL_CHAR, MIN_UPPERCASE } from '../../model/Password';
import { composeValidators, maxLength, required } from 'atupri-component-library/lib/core/utils/Validators';
import { useDispatch, useSelector } from 'react-redux';
import { selectCustomerInfo } from '../../redux/customer-info.slide';
import { dataTestId } from '../../helper';
import { AppDispatch } from '../../redux/store';
import { setLastUserInfoUpdate } from '../../redux/application.slide';
import { userInfoService } from '../../service';

const StyledCenterGrid = styled(Grid)`
    justify-content: center;
`;

const StyledTitle = styled(H2)`
    font-size: 2.5rem !important;
    line-height: inherit;
`;

const StyledGridButtons = styled(GridItem)`
    display: flex;
    justify-content: end;
`;

const PasswordCheck = ({
    validationState,
    count,
    text,
    minReq,
}: {
    validationState: ValidationState;
    text?: string;
    count?: number;
    minReq?: number;
}) => {
    const getColor = (validationState: ValidationState) => {
        switch (validationState) {
            case ValidationState.SUCCESS:
                return colors.success;
            case ValidationState.ERROR:
                return colors.error;
            /* istanbul ignore next */
            case ValidationState.INITIAL:
            default:
                return colors.black;
        }
    };

    const StyledDiv = styled.div`
        display: flex;
        align-items: center;

        color: ${getColor(validationState)};
    `;

    const StyledP = styled.p`
        padding-left: ${rem(spaces.gamma)};
        margin: unset;
    `;

    return (
        <StyledDiv>
            <Icon iconName={validationState === ValidationState.SUCCESS ? 'check' : 'cross'} />
            <StyledP>{minReq ? text + ` (${count}/${minReq})` : text}</StyledP>
        </StyledDiv>
    );
};

const ChangePasswordScreen = ({ onCancel }: { onCancel?: () => void }) => {
    const dispatch: AppDispatch = useDispatch();
    const customerInformation = useSelector(selectCustomerInfo);
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [httpErrorMessage, setHttpErrorMessage] = useState<string | undefined>(undefined);
    const initialPasswordValidation = {
        minChar: { validationState: ValidationState.INITIAL, count: 0 },
        minDigits: { validationState: ValidationState.INITIAL, count: 0 },
        minUpperCase: { validationState: ValidationState.INITIAL, count: 0 },
        minLowerCase: { validationState: ValidationState.INITIAL, count: 0 },
        minSpecialChar: { validationState: ValidationState.INITIAL, count: 0 },
        trivial: { validationState: ValidationState.INITIAL, count: 0 },
    };
    const [passwordValidation, setPasswordValidation] = useState(initialPasswordValidation);

    const handleSubmit = (values: any) => {
        userInfoService.changePassword({
            newPassword: values.newPassword,
            oldPassword: values.currentPassword,
        }).then(() => {
            dispatch(setLastUserInfoUpdate('password'));
        }).catch((error) => {
            setHttpErrorMessage(i18next.t(`shared.error.http.${error.response.data.debugMessage}`));
        });
    };

    return (
        <StyledCenterGrid>
            <GridItem size={10}>
                <GridItem size="full">
                    <StyledTitle>{i18next.t<string>('uam.changePasswordScreen.intro')}</StyledTitle>
                    <Spacer space="delta" />
                </GridItem>
                <Spacer space="delta" />
                <Form
                    onSubmit={handleSubmit}
                    render={({handleSubmit}) => (
                        <form onSubmit={handleSubmit}>
                            <Grid>
                                <GridItem size="full">
                                    <H2>{i18next.t<string>('uam.changePasswordScreen.currentPasswordIntro')}</H2>
                                    <Spacer space="delta" />
                                </GridItem>
                                <GridItemInput
                                    gridItemSize="full"
                                    label={i18next.t('uam.changePasswordScreen.currentPasswordLabel')}
                                    inputName="currentPassword"
                                    type="password"
                                />
                                <GridItem size="full">
                                    <Spacer space="delta" />
                                </GridItem>

                                <GridItem size="full">
                                    <H2>{i18next.t<string>('uam.changePasswordScreen.newPasswordIntro')}</H2>
                                    <Spacer space="delta" />
                                </GridItem>
                                <GridItemInput
                                    gridItemSize="full"
                                    label={i18next.t('uam.changePasswordScreen.newPasswordLabel')}
                                    inputName="newPassword"
                                    inputRightIcon={{
                                        iconColor: 'primary',
                                        iconName: showNewPassword ? 'show_pw' : 'hide_pw',
                                        onClick: () => setShowNewPassword(!showNewPassword),
                                    }}
                                    type={showNewPassword ? 'text' : 'password'}
                                    validators={composeValidators(
                                        required(i18next.t('uam.askPasswordScreen.invalidPasswordError')),
                                        maxLength(i18next.t('uam.askPasswordScreen.maxLengthError'), 256),
                                        passwordCriteriaMetValidator(
                                            i18next.t('uam.askPasswordScreen.invalidPasswordError'),
                                            {
                                                insuredNr: customerInformation?.insuredNumber?.toString() ?? '',
                                                firstName: customerInformation?.firstName ?? '',
                                                lastName: customerInformation?.lastName ?? '',
                                            },
                                            setPasswordValidation
                                        )
                                    )}
                                    passwordrules="minlength: 8; required: lower; required: upper; required: digit; required: special;"
                                />
                                <GridItem size="full">
                                    <Spacer space="delta" />
                                    <Stack space="beta">
                                        <PasswordCheck
                                            validationState={passwordValidation.minChar.validationState}
                                            count={passwordValidation.minChar.count}
                                            text={i18next.t('uam.changePasswordScreen.validations.minChar')}
                                            minReq={MIN_LENGTH}
                                        />
                                        <PasswordCheck
                                            validationState={passwordValidation.minDigits.validationState}
                                            count={passwordValidation.minDigits.count}
                                            text={i18next.t('uam.changePasswordScreen.validations.minDigits')}
                                            minReq={MIN_DIGITS}
                                        />
                                        <PasswordCheck
                                            validationState={passwordValidation.minUpperCase.validationState}
                                            count={passwordValidation.minUpperCase.count}
                                            text={i18next.t('uam.changePasswordScreen.validations.minUpperCase')}
                                            minReq={MIN_UPPERCASE}
                                        />
                                        <PasswordCheck
                                            validationState={passwordValidation.minLowerCase.validationState}
                                            count={passwordValidation.minLowerCase.count}
                                            text={i18next.t('uam.changePasswordScreen.validations.minLowerCase')}
                                            minReq={MIN_LOWERCASE}
                                        />
                                        <PasswordCheck
                                            validationState={passwordValidation.minSpecialChar.validationState}
                                            count={passwordValidation.minSpecialChar.count}
                                            text={i18next.t('uam.changePasswordScreen.validations.minSpecialChar')}
                                            minReq={MIN_SPECIAL_CHAR}
                                        />
                                        <PasswordCheck
                                            validationState={passwordValidation.trivial.validationState}
                                            count={passwordValidation.trivial.count}
                                            text={i18next.t('uam.changePasswordScreen.validations.trivial')}
                                        />
                                    </Stack>
                                    <Spacer space="delta" />
                                </GridItem>

                                <StyledGridButtons size="full">
                                    <span style={{color: colors.red800, lineHeight: 3.5, marginRight: '8px'}}>{httpErrorMessage}</span>
                                    <Button
                                        type="button"
                                        buttonText={i18next.t('shared.formButton.cancel') ?? ''}
                                        data-testid={dataTestId('cancel-button')}
                                        outlined
                                        onClick={onCancel}
                                    />
                                    <Button
                                        style={{ marginLeft: '8px' }}
                                        buttonText={i18next.t('shared.formButton.save') ?? ''}
                                        data-testid={dataTestId('save-button')}
                                        variant="primary"
                                    />
                                </StyledGridButtons>
                            </Grid>
                        </form>
                    )}
                />
            </GridItem>
        </StyledCenterGrid>
    );
};

export default ChangePasswordScreen;
