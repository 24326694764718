import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Container } from 'atupri-component-library/lib/helpers';
import { footerData, headerData } from '../../data';
import { Footer, MinimalHeader } from '@atupri/atupri-component-library';

const StyledContent = styled.div`
	flex: 1 0 auto;
`;

const MinimalisticHeaderFooter = ({ children }: MinimalisticHeaderFooterProps) => {

	return (
		<>
			<MinimalHeader {...headerData()} />
			<StyledContent>
				<Container>{children}</Container>
			</StyledContent>
            <Footer {...footerData()} />
		</>
	);
};

export type MinimalisticHeaderFooterProps = {
	children: ReactNode;
};

export default MinimalisticHeaderFooter;
