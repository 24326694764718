import config from '../config/api';
import { IdentificationLevel, UamOperationsApi } from '../generated';
import { AxiosResponse } from 'axios';

class LoginService {
    private readonly api: UamOperationsApi

    constructor() {
        this.api = new UamOperationsApi(config);
    }

    getIalLevel(): Promise<AxiosResponse<IdentificationLevel>> {
        return this.api.getIalLevel();
    }
}
const loginInfoService = new LoginService();
export default loginInfoService;
