import './config/i18n';
import React, { useEffect, useState } from 'react';
import process from 'process';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import FloatingFocusStyles from 'atupri-component-library/lib/core/styles/FloatingFocusStyles';
import GlobalStyles from 'atupri-component-library/lib/core/styles/GlobalStyles';
import '@atupri/atupri-component-library/styles';
import { GridPreview } from 'atupri-component-library/lib/helpers';
import { ErrorProvider } from './context';
import { logger, ScrollToTop } from './helper';
import UserInfoScreen from './screens/UserInfo/UserInfoScreen';
import BasePage from './components/BasePage';
import { AuthProvider, useAuth } from 'react-oidc-context';
import oidcConfig from './config/oidc';
import loginService from './service/loginService';
import { IdentificationLevel } from './generated';
import { getEnvironment } from './helper/environment';
import i18next from 'i18next';
import Loader from './components/Loader';
import Logout from './screens/Logout/Logout';

const App = () => {
    return (
        <ErrorProvider>
            <GlobalStyles />
            <FloatingFocusStyles />
            <ScrollToTop />
            <BasePage>
                <Routes>
                    <Route path="*" element={<UserInfoScreen />} />
                </Routes>
            </BasePage>
            {process.env.NODE_ENV === 'development' && <GridPreview />}
        </ErrorProvider>
    );
};

const SecuredApp = () => {
    const { signinRedirect, isAuthenticated, isLoading } = useAuth();
    const REQUESTED_PAGE_NAME = 'requestedPage';
    const [ialLevel, setIalLevel] = useState<IdentificationLevel | null>(null); // Initialize as null
    const isLogOutPath = window.location.pathname.startsWith('/logout');
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuthenticated && !isLoading && getEnvironment() !== 'demo') {
            signinRedirect({ extraQueryParams: { language: i18next.language } });
        }
    }, [isAuthenticated, signinRedirect, isLoading]);

    useEffect(() => {
        if (isAuthenticated && !isLogOutPath) {
            loginService
                .getIalLevel()
                .then((response) => {
                    // Set ialLevel
                    setIalLevel(response.data);
                })
                .catch((error: any) => {
                    logger('Logout', [`Token invalid -> revoke access`, error], true);
                    window.location.href = '/logout';
                });
        }
    }, [isAuthenticated, isLogOutPath]);

    useEffect(() => {
        const requestedPage = sessionStorage.getItem(REQUESTED_PAGE_NAME);
        if (isAuthenticated && requestedPage) {
            sessionStorage.removeItem(REQUESTED_PAGE_NAME);

            // Parse the URL to remove "code" and "state" parameters
            const url = new URL(requestedPage, window.location.origin);
            url.searchParams.delete('code'); // Remove "code" parameter
            url.searchParams.delete('state'); // Remove "state" parameter

            // Redirect to the cleaned URL
            navigate(`${url.pathname}${url.search}`);
        }
    }, [isAuthenticated, navigate]);

    if (isAuthenticated) {
        if (ialLevel === null) {
            return null; // Do not render anything until ialLevel is determined
        }

        // If the ialLevel is not 2
        if (ialLevel !== IdentificationLevel.IAL2) {
            window.location.href = '/logout';
            return null; // Prevent rendering anything after redirect
        }

        return <App />;
    } else if (getEnvironment() === 'demo') {
        return <App />;
    } else if (isLoading) {
        return <Loader />;
    }

    // Write the path and the parameters in the sessionStorage to access it again after login and forward to it
    sessionStorage.setItem(REQUESTED_PAGE_NAME, window.location.pathname + window.location.search);

    return <Loader />;
};

const WrappedSecuredApp = () => {
    return (
        <AuthProvider {...oidcConfig}>
            <Router>
                <Routes>
                    {/* add this route for logout outside of the RouteRenderer to avoid loading all the context and reduce API calls in a not proper logged in state*/}
                    <Route path={'/logout'} element={<Logout />} />
                    <Route path="/*" element={<SecuredApp />} />
                </Routes>
            </Router>
        </AuthProvider>
    );
};

export default withTranslation()(WrappedSecuredApp);
