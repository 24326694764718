import {
    ChangePasswordRequest,
    ConfirmNewMobileRequest,
    ResendSmsOtpRequest,
    SendSmsOtpRequest,
    UamOperationsApi,
} from '../generated';
import config from '../config/api';

class UserInfoService {
    private readonly api: UamOperationsApi;

    constructor() {
        this.api = new UamOperationsApi(config);
    }

    retrieveAccountInfo() {
        return this.api.retrieveAccountCurrentInfo();
    }

    changePassword(changePasswordRequest: ChangePasswordRequest) {
        return this.api.changePassword(changePasswordRequest);
    }

    changeMobilePhoneNumber(confirmNewMobileRequest: ConfirmNewMobileRequest) {
        return this.api.confirmNewMobilePhone(confirmNewMobileRequest);
    }

    sendSmsOtp(sendSmsOtpRequest: SendSmsOtpRequest) {
        return this.api.sendSmsOtp(sendSmsOtpRequest);
    }

    resendNewSmsOtp(resendSmsOtpRequest: ResendSmsOtpRequest) {
        return this.api.resendNewSmsOtp(resendSmsOtpRequest);
    }
}

const userInfoService = new UserInfoService();

export default userInfoService;
