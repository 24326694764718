import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { rgba } from 'polished';
import { colors } from 'atupri-component-library/lib/core/styles/theme/default';
import { Loader as LibLoader } from 'atupri-component-library/lib/components';

const StyledLoaderWrapper = styled.div(({ alignment, variant }: LoaderProps) => {
	const alignmentStyles = css`
		text-align: ${alignment};
	`;

	const variantStyles = [];
	if (variant === 'cover') {
		variantStyles.push(
			css`
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				background: ${rgba(colors.white, 0.75)};
				z-index: 1;
			`
		);
	}

	return [alignmentStyles, variantStyles];
});

const Loader = ({ alignment = 'center', variant, ...rest }: LoaderProps) => {
	return (
		<StyledLoaderWrapper alignment={alignment} variant={variant} {...rest}>
			<LibLoader color="secondary" size={32} strokeWidth={4} />
		</StyledLoaderWrapper>
	);
};

export type LoaderProps = {
	/** alignment of the PollAnimation */
	alignment?: 'left' | 'center' | 'right';
	/** variant of the PollAnimation */
	variant?: 'cover';
};

export default Loader;
