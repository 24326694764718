import { configureStore } from '@reduxjs/toolkit';
import applicationInfoReducer from './application.slide';
import customerInfoReducer from './customer-info.slide';

export function makeStore() {
	return configureStore({
		reducer: {
			applicationInfo: applicationInfoReducer,
			customerInfo: customerInfoReducer,
		},
	})
}

const store = makeStore();

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
