import i18next from 'i18next';
import { getAtuprichHost } from '../helper';
import { FooterProps } from '@atupri/atupri-component-library';

export const footerData = (): FooterProps => {
    const atuprichHost = getAtuprichHost();

    return {
        title: i18next.t('footer.title.text', 'Atupri begleitet Sie durch jede Lebenssituation'),
        logo: {
            image: {
                src: 'https://a.storyblok.com/f/253863/98x35/2fc018e7ab/logo-inverted.svg',
                alt: 'atupri logo inverted',
            },
            link: {
                href: atuprichHost + i18next.t('footer.legalLinks.atuprich.href', 'de'),
                title: 'home',
                trackingLabel: 'atupri_logo_footer'
            },
        },
        quickLinks: {
            title: i18next.t('footer.quickLinks.title', 'Direkt zu'),
            links: [
                {
                    label: i18next.t('footer.quickLinks.links.insurance.label', 'Versicherungen'),
                    href: atuprichHost + i18next.t('footer.quickLinks.links.insurance.href', 'de/versicherungen'),
                    trackingLabel: 'versicherungen',
                },
                {
                    label: i18next.t('footer.quickLinks.links.doItYourself.label', 'Selbst erledigen'),
                    href: atuprichHost + i18next.t('footer.quickLinks.links.doItYourself.href', 'de/service-support'),
                    trackingLabel: 'selbstErledigen',
                },
                {
                    label: i18next.t('footer.quickLinks.links.medgateApp.label', 'Medgate FAQ'),
                    href: atuprichHost + i18next.t('footer.quickLinks.links.medgateApp.href', 'de/service-support/faq/medgate'),
                    trackingLabel: 'medgateFaq',
                },
                {
                    label: i18next.t('footer.quickLinks.links.newsletter.label', 'Newsletter'),
                    href: atuprichHost + i18next.t('footer.quickLinks.links.newsletter.href', 'de/newsletter'),
                    trackingLabel: 'newsletter',
                },
            ],
        },
        contactInfo: [
            {
                title: i18next.t('footer.contactInfo.title', 'Bei Fragen zur Versicherung'),
                infoText: i18next.t('footer.contactInfo.infoText', 'Mo–Fr von 8 bis 17 Uhr erreichbar'),
                phoneNumber: i18next.t('footer.contactInfo.phoneNumber', '0800 200 888'),
                button: {
                    children: i18next.t('footer.contactInfo.button.children', 'Kontakt aufnehmen') as string,
                    link: {
                        href: atuprichHost + i18next.t('footer.contactInfo.button.link.href', 'de/kontakt'),
                        title: 'kontakt',
                        trackingLabel: 'kontakt',
                    },
                },
            },
        ],
        socialLinks: [
            {
                icon: 'logo_tiktok', link: {
                    href: i18next.t(
                        'footer.socialLinks.links.tiktok.link.href',
                        'https://www.tiktok.com/@atupri.ch',
                    ),
                    title: i18next.t('footer.socialLinks.links.tiktok.link.title', 'TikTok'),
                    trackingLabel: 'tiktok',
                },
            },
            {
                icon: 'logo_instagram', link: {
                    href: i18next.t(
                        'footer.socialLinks.links.instagram.link.href',
                        'https://www.instagram.com/atupri.ch',
                    ),
                    title: i18next.t('footer.socialLinks.links.instagram.linkText', 'Instagram'),
                    trackingLabel: 'instagram',
                },
            },
            { icon: 'logo_linkedin', link: { href: i18next.t(
                        'footer.socialLinks.links.linkedin.link.href',
                        'https://www.linkedin.com/company/atupri-gesundheitsversicherung/',
                    ), title: i18next.t('footer.socialLinks.links.linkedin.linkText', 'Linkedin'), trackingLabel: 'linkedin' } },
        ],
        legalLinks: [
            {
                label: i18next.t('footer.legalLinks.atuprich.label', 'atupri.ch'),
                href: atuprichHost + i18next.t('footer.legalLinks.atuprich.href', 'de'),
                trackingLabel: 'atuprich',
            },
            {
                label: i18next.t('footer.legalLinks.impressum.label', 'Impressum'),
                href: atuprichHost + i18next.t('footer.legalLinks.impressum.href', 'de/impressum '),
                trackingLabel: 'impressum',
            },
            {
                label: i18next.t('footer.legalLinks.legal.label', 'Rechtliche Hinweise'),
                href: atuprichHost + i18next.t('footer.legalLinks.legal.href', 'de/rechtliches'),
                trackingLabel: 'rechtliches',
            },
            {
                label: i18next.t('footer.legalLinks.dataProtection.label', 'Datenschutz'),
                href: atuprichHost + i18next.t('footer.legalLinks.dataProtection.href', 'de/rechtliches'),
                trackingLabel: 'datenschutz',
            },
        ],
    };
};

export default footerData;
