import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { logger } from '../../helper';
import Loader from '../../components/Loader';

const Logout = () => {
    const { signoutRedirect, revokeTokens, removeUser, stopSilentRenew, clearStaleState } = useAuth();

    useEffect(() => {
        const logoutAndCleanup = async () => {
            try {
                stopSilentRenew();   // Stop automatic silent renewal
                await revokeTokens(); // Revoke access & refresh tokens
                await removeUser();   // Remove user session from client storage
                await clearStaleState(); // Clear any stale authentication state
                sessionStorage.clear();
                localStorage.clear();

                logger("Logout", "Logout and cleanup completed.")

                await signoutRedirect();
            } catch (error) {
                logger("Logout", [`Failed to logout`, error], true);
            }
        };

        logoutAndCleanup().then(() => logger("Logout", "Logout was successful"));
    }, [signoutRedirect, revokeTokens, removeUser, stopSilentRenew, clearStaleState]);

    return <Loader />
};

export default Logout;
