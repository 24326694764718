import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from './store';
import { FormState } from '../model/FormState';

export interface ApplicationInfo {
	loading: boolean;
	formState: FormState;
	businessErrorMessage?: string;

	lastUserInfoUpdate?: 'email' | 'mobile' | 'password';
	migration?: boolean;
}

export interface ApplicationInfoState {
	value: ApplicationInfo;
}

const initialState: ApplicationInfoState = {
	value: {
		loading: false,
		formState: FormState.START,

		lastUserInfoUpdate: undefined,
		migration: false,
	}
}

export const applicationInfoSlice = createSlice({
	name: 'applicationInfo',
	initialState,
	reducers: {
		update: (state, action) => {
			state.value = {
				...state.value,
				...action.payload,
			}
		},
		reset: (state) => {
			state.value = initialState.value;
		},
		setFormState: (state, action: PayloadAction<FormState>) => {
			state.value = {
				...state.value,
				businessErrorMessage: undefined,
				formState: action.payload,
			}
		},
		setFormStateErrorWithMessage: (state, action: PayloadAction<string>) => {
			state.value = {
				...state.value,
				businessErrorMessage: action.payload,
				formState: FormState.ERROR_DETAILED,
			}
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.value = {
				...state.value,
				loading: action.payload
			}
		},
		setLastUserInfoUpdate: (state, action: PayloadAction<'email' | 'mobile' | 'password' | undefined>) => {
			state.value = {
				...state.value,
				lastUserInfoUpdate: action.payload
			}
		}
	}
});

export const {
	update,
	setFormState,
	setFormStateErrorWithMessage,
	setLoading,
	setLastUserInfoUpdate,
} = applicationInfoSlice.actions;

export const selectApplicationInfo = (state: AppState) => state.applicationInfo.value;

export default applicationInfoSlice.reducer;
