
/**
 * Validator checks if the input value is undefined,
 * has a value of zero or if it is a string, that it is empty
 * If the value is falsy it resets the passwordValidation state
 * @param message the error text, which should be shown
 * @return {(VALID|message)} VALID or the error text
 */
export const required = (message: string) => (value: string) => {
    return value ? undefined : message;
};

export const formatContractNumber = (value: string) => (value ? value.replace(/[^0-9]/, '') : '');
