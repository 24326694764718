import styled from '@emotion/styled';
import { Box, Grid, GridItem, Spacer } from 'atupri-component-library/lib/helpers';
import i18next from 'i18next';
import { GridItemInput } from '../../components/FormControls';
import { Button, H2, Icon } from 'atupri-component-library/lib/primitives';
import { Form } from 'react-final-form';
import { composeValidators, emailFormatValid, required } from 'atupri-component-library/lib/core/utils/Validators';
import { useDispatch, useSelector } from 'react-redux';
import { selectCustomerInfo, update } from '../../redux/customer-info.slide';
import { dataTestId } from '../../helper';
import { rem } from 'polished';
import { useState } from 'react';
import { AppDispatch } from '../../redux/store';
import { setLastUserInfoUpdate } from '../../redux/application.slide';

const StyledCenterGrid = styled(Grid)`
    justify-content: center;
`;

const StyledTitle = styled(H2)`
    font-size: 2.5rem !important;
    line-height: inherit;
`;

const StyledGridButtons = styled(GridItem)`
    display: flex;
    justify-content: end;
`;

const ChangeEmailScreen = ({ onCancel }: { onCancel?: () => void }) => {
    const dispatch: AppDispatch = useDispatch();
    const customerInformation = useSelector(selectCustomerInfo);
    const [isEmailChanged, setIsEmailChanged] = useState(false);

    const BoxMessageAfterChangeEmail = () => {
        return (
            <>
                <GridItem size="full">
                    <StyledTitle>{i18next.t<string>('uam.changeEmailScreen.messageBox.intro')}</StyledTitle>
                    <Spacer space="delta" />
                </GridItem>
                <Box
                    padding="regular"
                    shadow="ctaBlueHover"
                    borderColor="blue500"
                    borderRadius={4}
                    background="blue400"
                >
                    <Grid>
                        <Icon iconName="check_outlined" style={{ marginTop: rem(4) }} />
                        <GridItem>
                            <strong>{i18next.t<string>('uam.changeEmailScreen.messageBox.title')}</strong> <br />
                            {i18next.t<string>('uam.changeEmailScreen.messageBox.paragraph')}
                        </GridItem>
                    </Grid>
                </Box>
            </>
        );
    };

    const handleSubmit = (values: any) => {
        setIsEmailChanged(true);

        dispatch(update({ emailAddress: values.email }));
        dispatch(setLastUserInfoUpdate('email'));
    };

    return isEmailChanged ? (
        <BoxMessageAfterChangeEmail />
    ) : (
        <StyledCenterGrid>
            <GridItem size={10}>
                <GridItem size="full">
                    <StyledTitle>{i18next.t<string>('uam.changeEmailScreen.intro')}</StyledTitle>
                    <Spacer space="delta" />
                </GridItem>
                <Spacer space="delta" />
                <Form
                    initialValues={{
                        email: customerInformation?.emailAddress ?? '',
                    }}
                    onSubmit={handleSubmit}
                    render={({ handleSubmit, values }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid>
                                <GridItem size="full">
                                    <H2>{i18next.t<string>('uam.changeEmailScreen.newEmailIntro')}</H2>
                                    <Spacer space="delta" />
                                </GridItem>
                                <GridItemInput
                                    beforeSubmit={() => {
                                        values.email = values.email.toLowerCase();
                                    }}
                                    gridItemSize="full"
                                    inputName="email"
                                    maxLength={70}
                                    label={i18next.t('uam.changeEmailScreen.emailLabel')}
                                    validators={composeValidators(
                                        required(i18next.t('uam.changeEmailScreen.validations.emailRequiredError')),
                                        emailFormatValid(i18next.t('uam.changeEmailScreen.validations.invalidEmailError'))
                                    )}
                                />

                                <GridItem size="full">
                                    <Spacer space="delta" />
                                </GridItem>

                                <StyledGridButtons size="full">
                                    <Button
                                        type="button"
                                        buttonText={i18next.t('shared.formButton.cancel') ?? ''}
                                        data-testid={dataTestId('cancel-button')}
                                        outlined
                                        onClick={onCancel}
                                    />
                                    <Button
                                        type="submit"
                                        style={{ marginLeft: '8px' }}
                                        buttonText={i18next.t('shared.formButton.save') ?? ''}
                                        data-testid={dataTestId('save-button')}
                                        variant="primary"
                                    />
                                </StyledGridButtons>
                            </Grid>
                        </form>
                    )}
                />
            </GridItem>
        </StyledCenterGrid>
    );
};

export default ChangeEmailScreen;
