import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from './store';

export interface CustomerInfo {
	registerId?: string;
	insuredNumber?: number;
	firstName?: string;
	lastName?: string;

	password?: string;

	emailAddress?: string;
	dob?: string;

	mobileInfo?: {
		iso: string;
		countryCode: string;
		phoneNumber: string;
	}
	contractNumber?: number;
}

export interface CustomerInfoState {
	value: CustomerInfo | null;
}

const initialState: CustomerInfoState = {
	value: null
}

export const customerInfoSlice = createSlice({
	name: 'customerInfo',
	initialState,
	reducers: {
		reset: (state) => {
			state.value = null;
		},
		update: (state, action: PayloadAction<Partial<CustomerInfo>>) => {
			state.value = {
				...state.value,
				...action.payload,
			}
		}
	}
});

export const { update } = customerInfoSlice.actions;

export const selectCustomerInfo = (state: AppState) => state.customerInfo.value;

export default customerInfoSlice.reducer;
