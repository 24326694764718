/* tslint:disable */
/* eslint-disable */
/**
 * RegComp REST API
 * This Java REST API delivers the data used by the RegComp frontend.<br/> For errors, inconsistencies or suggestions, please create a JIRA ticket in [ATP JIRA PROJECT](https://atupri.atlassian.net/browse/ATP).<br/> NOTE: All operations are not authenticated, since by definition the user does not have yet an account 
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: digital@atupri.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApiResponse
 */
export interface ApiResponse {
    /**
     * email address for prefilling. Only filled when the mobile number needs to be displayed to the user.
     * @type {string}
     * @memberof ApiResponse
     */
    'emailAddress'?: string;
    /**
     * Indicator that tells the current registration request is about user migration
     * @type {boolean}
     * @memberof ApiResponse
     */
    'migration'?: boolean;
    /**
     * Indicator that tells the current registration request is about user needsResidenceConfirmation
     * @type {boolean}
     * @memberof ApiResponse
     */
    'needsResidenceConfirmation'?: boolean;
    /**
     * Next action To be performed
     * @type {string}
     * @memberof ApiResponse
     */
    'nextAction': ApiResponseNextActionEnum;
    /**
     * 
     * @type {InfoForPasswordValidationRules}
     * @memberof ApiResponse
     */
    'passwordExtraInfo'?: InfoForPasswordValidationRules;
    /**
     * Customer mobile phone known by Atupri as a string in E.164 format. Only filled when the mobile number needs to be  displayed to the user.
     * @type {string}
     * @memberof ApiResponse
     */
    'phoneNumber'?: string;
    /**
     * Registration request Id
     * @type {string}
     * @memberof ApiResponse
     */
    'regReqId': string;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiResponseNextActionEnum {
    ASK_AUTHENTICATION = 'ASK_AUTHENTICATION',
    ASK_EMAIL_ADDRESS = 'ASK_EMAIL_ADDRESS',
    INFORM_EMAIL_SENT = 'INFORM_EMAIL_SENT',
    ASK_MOBILE_NUM = 'ASK_MOBILE_NUM',
    ASK_SMS_OTP = 'ASK_SMS_OTP',
    ASK_PASSWORD = 'ASK_PASSWORD',
    INFORM_END = 'INFORM_END',
    INFORM_END_NO_LOGIN = 'INFORM_END_NO_LOGIN'
}

/**
 * Ask email address to user
 * @export
 * @interface AskEmailAddressRequest
 */
export interface AskEmailAddressRequest {
    /**
     * email address
     * @type {string}
     * @memberof AskEmailAddressRequest
     */
    'emailAddress': string;
    /**
     * 
     * @type {Language}
     * @memberof AskEmailAddressRequest
     */
    'language': Language;
}
/**
 * Ask mobile number to user
 * @export
 * @interface AskMobileNumberRequest
 */
export interface AskMobileNumberRequest {
    /**
     * mobile phone number in E.164 format
     * @type {string}
     * @memberof AskMobileNumberRequest
     */
    'mobileNumber': string;
}
/**
 * Ask password to user
 * @export
 * @interface AskPasswordRequest
 */
export interface AskPasswordRequest {
    /**
     * 
     * @type {Language}
     * @memberof AskPasswordRequest
     */
    'language': Language;
    /**
     * user Password 
     * @type {string}
     * @memberof AskPasswordRequest
     */
    'password': string;
}
/**
 * Ask user to confirm the OTP that was sent to him via SMS
 * @export
 * @interface AskSmsOtpRequest
 */
export interface AskSmsOtpRequest {
    /**
     * SMS OTP to be confirmed
     * @type {string}
     * @memberof AskSmsOtpRequest
     */
    'otp': string;
}
/**
 * 
 * @export
 * @interface ChangePasswordRequest
 */
export interface ChangePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    'newPassword'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    'oldPassword'?: string;
}
/**
 * 
 * @export
 * @interface ConfirmNewMobileRequest
 */
export interface ConfirmNewMobileRequest {
    /**
     * Otp code to match
     * @type {string}
     * @memberof ConfirmNewMobileRequest
     */
    'otp'?: string;
    /**
     * Correlation id that identifies the phone number to be sent
     * @type {string}
     * @memberof ConfirmNewMobileRequest
     */
    'smsId'?: string;
}
/**
 * Data needed to continue a user migration registration after the handover
 * @export
 * @interface ContinueUserMigrationRequest
 */
export interface ContinueUserMigrationRequest {
    /**
     * 
     * @type {Language}
     * @memberof ContinueUserMigrationRequest
     */
    'language'?: Language;
    /**
     * identify the registration request for this operation. It should be sent as a Bearer token although no OAuth authorization server produces it, nor is processed by any OAuth flow.
     * @type {string}
     * @memberof ContinueUserMigrationRequest
     */
    'regReqId': string;
}
/**
 * 
 * @export
 * @interface CurrentAccountInfoResponse
 */
export interface CurrentAccountInfoResponse {
    /**
     * Email address stored on user\'s IAM account
     * @type {string}
     * @memberof CurrentAccountInfoResponse
     */
    'emailAddress'?: string;
    /**
     * First name from the logged user
     * @type {string}
     * @memberof CurrentAccountInfoResponse
     */
    'firstName'?: string;
    /**
     * Insured number from the logged user
     * @type {number}
     * @memberof CurrentAccountInfoResponse
     */
    'insuredNumber'?: number;
    /**
     * Last name from the logged user
     * @type {string}
     * @memberof CurrentAccountInfoResponse
     */
    'lastName'?: string;
    /**
     * Mobile phone number stored on user\'s IAM account (in E.164 format)
     * @type {string}
     * @memberof CurrentAccountInfoResponse
     */
    'mobilePhoneNumber'?: string;
}
/**
 * General API error response for various exceptions
 * @export
 * @interface ExceptionResponse
 */
export interface ExceptionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ExceptionResponse
     */
    'businessError'?: boolean;
    /**
     * HTTP status text
     * @type {string}
     * @memberof ExceptionResponse
     */
    'code': string;
    /**
     * Debug message, empty in production
     * @type {string}
     * @memberof ExceptionResponse
     */
    'debugMessage': string;
    /**
     * Error message
     * @type {string}
     * @memberof ExceptionResponse
     */
    'message': string;
    /**
     * HTTP status code
     * @type {number}
     * @memberof ExceptionResponse
     */
    'status': number;
    /**
     * Date and time when the error occurred
     * @type {string}
     * @memberof ExceptionResponse
     */
    'timestamp': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum IdentificationLevel {
    NONE = 'NONE',
    IAL1 = 'IAL1',
    IAL2 = 'IAL2'
}

/**
 * information needed to do the password validation. Only filled when password needs to be requested to the user
 * @export
 * @interface InfoForPasswordValidationRules
 */
export interface InfoForPasswordValidationRules {
    /**
     * Customer first name
     * @type {string}
     * @memberof InfoForPasswordValidationRules
     */
    'firstName'?: string;
    /**
     * Customer insured number
     * @type {number}
     * @memberof InfoForPasswordValidationRules
     */
    'insuredNumber'?: number;
    /**
     * Customer last name
     * @type {string}
     * @memberof InfoForPasswordValidationRules
     */
    'lastName'?: string;
}
/**
 * user language for communicating with the insured
 * @export
 * @enum {string}
 */

export enum Language {
    de = 'de',
    fr = 'fr',
    it = 'it'
}

/**
 * 
 * @export
 * @interface ResendSmsOtpRequest
 */
export interface ResendSmsOtpRequest {
    /**
     * Correlation id that identifies the phone number to be sent
     * @type {string}
     * @memberof ResendSmsOtpRequest
     */
    'smsId'?: string;
}
/**
 * Information needed to initiate the resumption of a previous process.
 * @export
 * @interface ResumeRegistrationRequest
 */
export interface ResumeRegistrationRequest {
    /**
     * Token that identifies a previous saved registration request
     * @type {string}
     * @memberof ResumeRegistrationRequest
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface SendSmsOtpRequest
 */
export interface SendSmsOtpRequest {
    /**
     * New mobile phone number in E.164 format
     * @type {string}
     * @memberof SendSmsOtpRequest
     */
    'newMobilePhone'?: string;
}
/**
 * 
 * @export
 * @interface SendSmsOtpResponse
 */
export interface SendSmsOtpResponse {
    /**
     * correlation id for the other change mobile phone actions
     * @type {string}
     * @memberof SendSmsOtpResponse
     */
    'smsId': string;
}
/**
 * Data needed to start a registration initiated from the service center
 * @export
 * @interface StartNewCustomerRequest
 */
export interface StartNewCustomerRequest {
    /**
     * Date of birth from the insured. Between 1799-12-31 and 2100-01-01
     * @type {string}
     * @memberof StartNewCustomerRequest
     */
    'dob': string;
    /**
     * Insured email address
     * @type {string}
     * @memberof StartNewCustomerRequest
     */
    'emailAddress': string;
    /**
     * Atupri\'s insured number
     * @type {number}
     * @memberof StartNewCustomerRequest
     */
    'insuredNumber': number;
    /**
     * 
     * @type {Language}
     * @memberof StartNewCustomerRequest
     */
    'language'?: Language;
    /**
     * mobile phone number in E.164 format
     * @type {string}
     * @memberof StartNewCustomerRequest
     */
    'mobileNumber'?: string;
}
/**
 * Data returned by the internal api methods
 * @export
 * @interface StartResponse
 */
export interface StartResponse {
    /**
     * identify the registration request for this operation. It should be sent as a Bearer token although no OAuth authorization server produces it, nor is processed by any OAuth flow.
     * @type {string}
     * @memberof StartResponse
     */
    'regReqId': string;
}
/**
 * Data to needed to start a self service registration
 * @export
 * @interface StartSelfServiceRequest
 */
export interface StartSelfServiceRequest {
    /**
     * Date of birth from the insurance 
     * @type {string}
     * @memberof StartSelfServiceRequest
     */
    'dob': string;
    /**
     * Atupri\'s insured number
     * @type {number}
     * @memberof StartSelfServiceRequest
     */
    'insuredNumber': number;
}
/**
 * Data needed to start a registration initiated from the service center
 * @export
 * @interface StartServiceCenterRequest
 */
export interface StartServiceCenterRequest {
    /**
     * Date of birth from the insured. Between 1799-12-31 and 2100-01-01
     * @type {string}
     * @memberof StartServiceCenterRequest
     */
    'dob': string;
    /**
     * Insured email address
     * @type {string}
     * @memberof StartServiceCenterRequest
     */
    'emailAddress': string;
    /**
     * Atupri\'s insured number
     * @type {number}
     * @memberof StartServiceCenterRequest
     */
    'insuredNumber': number;
    /**
     * 
     * @type {Language}
     * @memberof StartServiceCenterRequest
     */
    'language'?: Language;
    /**
     * mobile phone number in E.164 format
     * @type {string}
     * @memberof StartServiceCenterRequest
     */
    'mobileNumber'?: string;
}
/**
 * Data needed to start a user migration registration request
 * @export
 * @interface StartUserMigrationRequest
 */
export interface StartUserMigrationRequest {
    /**
     * Date of birth from the insured. Between 1799-12-31 and 2100-01-01
     * @type {string}
     * @memberof StartUserMigrationRequest
     */
    'dob': string;
    /**
     * Insured email address
     * @type {string}
     * @memberof StartUserMigrationRequest
     */
    'emailAddress': string;
    /**
     * Atupri\'s insured number
     * @type {number}
     * @memberof StartUserMigrationRequest
     */
    'insuredNumber': number;
    /**
     * 
     * @type {Language}
     * @memberof StartUserMigrationRequest
     */
    'language'?: Language;
    /**
     * mobile phone number in E.164 format
     * @type {string}
     * @memberof StartUserMigrationRequest
     */
    'mobileNumber'?: string;
}

/**
 * AskEmailAddressApi - axios parameter creator
 * @export
 */
export const AskEmailAddressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Specifies the email address number that is going to be assigned to registration process.
         * @summary Allows to set the email address for the registration process
         * @param {string} authorization identify the registration request for this operation. It should be sent as a Bearer token although no OAuth authorization server produces it, nor is processed by any OAuth flow.
         * @param {AskEmailAddressRequest} askEmailAddressRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEmailAddress: async (authorization: string, askEmailAddressRequest: AskEmailAddressRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('setEmailAddress', 'authorization', authorization)
            // verify required parameter 'askEmailAddressRequest' is not null or undefined
            assertParamExists('setEmailAddress', 'askEmailAddressRequest', askEmailAddressRequest)
            const localVarPath = `/api/registration-req/email-addr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(askEmailAddressRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AskEmailAddressApi - functional programming interface
 * @export
 */
export const AskEmailAddressApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AskEmailAddressApiAxiosParamCreator(configuration)
    return {
        /**
         * Specifies the email address number that is going to be assigned to registration process.
         * @summary Allows to set the email address for the registration process
         * @param {string} authorization identify the registration request for this operation. It should be sent as a Bearer token although no OAuth authorization server produces it, nor is processed by any OAuth flow.
         * @param {AskEmailAddressRequest} askEmailAddressRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setEmailAddress(authorization: string, askEmailAddressRequest: AskEmailAddressRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setEmailAddress(authorization, askEmailAddressRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AskEmailAddressApi - factory interface
 * @export
 */
export const AskEmailAddressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AskEmailAddressApiFp(configuration)
    return {
        /**
         * Specifies the email address number that is going to be assigned to registration process.
         * @summary Allows to set the email address for the registration process
         * @param {string} authorization identify the registration request for this operation. It should be sent as a Bearer token although no OAuth authorization server produces it, nor is processed by any OAuth flow.
         * @param {AskEmailAddressRequest} askEmailAddressRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEmailAddress(authorization: string, askEmailAddressRequest: AskEmailAddressRequest, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.setEmailAddress(authorization, askEmailAddressRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AskEmailAddressApi - object-oriented interface
 * @export
 * @class AskEmailAddressApi
 * @extends {BaseAPI}
 */
export class AskEmailAddressApi extends BaseAPI {
    /**
     * Specifies the email address number that is going to be assigned to registration process.
     * @summary Allows to set the email address for the registration process
     * @param {string} authorization identify the registration request for this operation. It should be sent as a Bearer token although no OAuth authorization server produces it, nor is processed by any OAuth flow.
     * @param {AskEmailAddressRequest} askEmailAddressRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AskEmailAddressApi
     */
    public setEmailAddress(authorization: string, askEmailAddressRequest: AskEmailAddressRequest, options?: AxiosRequestConfig) {
        return AskEmailAddressApiFp(this.configuration).setEmailAddress(authorization, askEmailAddressRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AskMobileNumberApi - axios parameter creator
 * @export
 */
export const AskMobileNumberApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Specifies the mobile phone number that is going to be assigned to registration process.
         * @summary Allows to set the mobile phone number for the registration process
         * @param {string} authorization identify the registration request for this operation. It should be sent as a Bearer token although no OAuth authorization server produces it, nor is processed by any OAuth flow.
         * @param {AskMobileNumberRequest} askMobileNumberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMobilePhoneNumber: async (authorization: string, askMobileNumberRequest: AskMobileNumberRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('setMobilePhoneNumber', 'authorization', authorization)
            // verify required parameter 'askMobileNumberRequest' is not null or undefined
            assertParamExists('setMobilePhoneNumber', 'askMobileNumberRequest', askMobileNumberRequest)
            const localVarPath = `/api/registration-req/mobile-num`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(askMobileNumberRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AskMobileNumberApi - functional programming interface
 * @export
 */
export const AskMobileNumberApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AskMobileNumberApiAxiosParamCreator(configuration)
    return {
        /**
         * Specifies the mobile phone number that is going to be assigned to registration process.
         * @summary Allows to set the mobile phone number for the registration process
         * @param {string} authorization identify the registration request for this operation. It should be sent as a Bearer token although no OAuth authorization server produces it, nor is processed by any OAuth flow.
         * @param {AskMobileNumberRequest} askMobileNumberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setMobilePhoneNumber(authorization: string, askMobileNumberRequest: AskMobileNumberRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setMobilePhoneNumber(authorization, askMobileNumberRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AskMobileNumberApi - factory interface
 * @export
 */
export const AskMobileNumberApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AskMobileNumberApiFp(configuration)
    return {
        /**
         * Specifies the mobile phone number that is going to be assigned to registration process.
         * @summary Allows to set the mobile phone number for the registration process
         * @param {string} authorization identify the registration request for this operation. It should be sent as a Bearer token although no OAuth authorization server produces it, nor is processed by any OAuth flow.
         * @param {AskMobileNumberRequest} askMobileNumberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMobilePhoneNumber(authorization: string, askMobileNumberRequest: AskMobileNumberRequest, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.setMobilePhoneNumber(authorization, askMobileNumberRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AskMobileNumberApi - object-oriented interface
 * @export
 * @class AskMobileNumberApi
 * @extends {BaseAPI}
 */
export class AskMobileNumberApi extends BaseAPI {
    /**
     * Specifies the mobile phone number that is going to be assigned to registration process.
     * @summary Allows to set the mobile phone number for the registration process
     * @param {string} authorization identify the registration request for this operation. It should be sent as a Bearer token although no OAuth authorization server produces it, nor is processed by any OAuth flow.
     * @param {AskMobileNumberRequest} askMobileNumberRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AskMobileNumberApi
     */
    public setMobilePhoneNumber(authorization: string, askMobileNumberRequest: AskMobileNumberRequest, options?: AxiosRequestConfig) {
        return AskMobileNumberApiFp(this.configuration).setMobilePhoneNumber(authorization, askMobileNumberRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AskPasswordApi - axios parameter creator
 * @export
 */
export const AskPasswordApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This request will change the password of the currently logged in user.<br>A new password must fulfill the following complexity rules:<br><br>- Have a length between 8 and 256 characters.<br>- Have at least one upper-case character.<br>- Have at least one lower-case character.<br>- Have at least two digit characters.<br>- Have at least one symbol / special character (ASCII symbols, Latin-1 symbols, Latin-1 math, Unicode symbols, Unicode currency).<br>Chars: !\"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~¡¢£¤¥¦§¨©ª«¬­®¯°±²³´µ¶·¸¹º»¼½¾¿×÷–—―‗‘’‚‛“”„†‡•…‰′″‹›‼‾⁄⁊₠₡₢₣₤₥₦₧₨₩₪₫€₭₮₯₰₱₲₳₴₵₶₷₸₹₺₻₼₽₾ <br>- Doesn\'t contain the first / last name or the insured number in the password. <br><br><br>See the 400 - Bad Request for more details about the returned validation errors.
         * @summary Allows to set a password for the registration process
         * @param {string} authorization identify the registration request for this operation. It should be sent as a Bearer token although no OAuth authorization server produces it, nor is processed by any OAuth flow.
         * @param {AskPasswordRequest} askPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNewPassword: async (authorization: string, askPasswordRequest: AskPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('setNewPassword', 'authorization', authorization)
            // verify required parameter 'askPasswordRequest' is not null or undefined
            assertParamExists('setNewPassword', 'askPasswordRequest', askPasswordRequest)
            const localVarPath = `/api/registration-req/pwd`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(askPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AskPasswordApi - functional programming interface
 * @export
 */
export const AskPasswordApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AskPasswordApiAxiosParamCreator(configuration)
    return {
        /**
         * This request will change the password of the currently logged in user.<br>A new password must fulfill the following complexity rules:<br><br>- Have a length between 8 and 256 characters.<br>- Have at least one upper-case character.<br>- Have at least one lower-case character.<br>- Have at least two digit characters.<br>- Have at least one symbol / special character (ASCII symbols, Latin-1 symbols, Latin-1 math, Unicode symbols, Unicode currency).<br>Chars: !\"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~¡¢£¤¥¦§¨©ª«¬­®¯°±²³´µ¶·¸¹º»¼½¾¿×÷–—―‗‘’‚‛“”„†‡•…‰′″‹›‼‾⁄⁊₠₡₢₣₤₥₦₧₨₩₪₫€₭₮₯₰₱₲₳₴₵₶₷₸₹₺₻₼₽₾ <br>- Doesn\'t contain the first / last name or the insured number in the password. <br><br><br>See the 400 - Bad Request for more details about the returned validation errors.
         * @summary Allows to set a password for the registration process
         * @param {string} authorization identify the registration request for this operation. It should be sent as a Bearer token although no OAuth authorization server produces it, nor is processed by any OAuth flow.
         * @param {AskPasswordRequest} askPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setNewPassword(authorization: string, askPasswordRequest: AskPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setNewPassword(authorization, askPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AskPasswordApi - factory interface
 * @export
 */
export const AskPasswordApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AskPasswordApiFp(configuration)
    return {
        /**
         * This request will change the password of the currently logged in user.<br>A new password must fulfill the following complexity rules:<br><br>- Have a length between 8 and 256 characters.<br>- Have at least one upper-case character.<br>- Have at least one lower-case character.<br>- Have at least two digit characters.<br>- Have at least one symbol / special character (ASCII symbols, Latin-1 symbols, Latin-1 math, Unicode symbols, Unicode currency).<br>Chars: !\"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~¡¢£¤¥¦§¨©ª«¬­®¯°±²³´µ¶·¸¹º»¼½¾¿×÷–—―‗‘’‚‛“”„†‡•…‰′″‹›‼‾⁄⁊₠₡₢₣₤₥₦₧₨₩₪₫€₭₮₯₰₱₲₳₴₵₶₷₸₹₺₻₼₽₾ <br>- Doesn\'t contain the first / last name or the insured number in the password. <br><br><br>See the 400 - Bad Request for more details about the returned validation errors.
         * @summary Allows to set a password for the registration process
         * @param {string} authorization identify the registration request for this operation. It should be sent as a Bearer token although no OAuth authorization server produces it, nor is processed by any OAuth flow.
         * @param {AskPasswordRequest} askPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNewPassword(authorization: string, askPasswordRequest: AskPasswordRequest, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.setNewPassword(authorization, askPasswordRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AskPasswordApi - object-oriented interface
 * @export
 * @class AskPasswordApi
 * @extends {BaseAPI}
 */
export class AskPasswordApi extends BaseAPI {
    /**
     * This request will change the password of the currently logged in user.<br>A new password must fulfill the following complexity rules:<br><br>- Have a length between 8 and 256 characters.<br>- Have at least one upper-case character.<br>- Have at least one lower-case character.<br>- Have at least two digit characters.<br>- Have at least one symbol / special character (ASCII symbols, Latin-1 symbols, Latin-1 math, Unicode symbols, Unicode currency).<br>Chars: !\"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~¡¢£¤¥¦§¨©ª«¬­®¯°±²³´µ¶·¸¹º»¼½¾¿×÷–—―‗‘’‚‛“”„†‡•…‰′″‹›‼‾⁄⁊₠₡₢₣₤₥₦₧₨₩₪₫€₭₮₯₰₱₲₳₴₵₶₷₸₹₺₻₼₽₾ <br>- Doesn\'t contain the first / last name or the insured number in the password. <br><br><br>See the 400 - Bad Request for more details about the returned validation errors.
     * @summary Allows to set a password for the registration process
     * @param {string} authorization identify the registration request for this operation. It should be sent as a Bearer token although no OAuth authorization server produces it, nor is processed by any OAuth flow.
     * @param {AskPasswordRequest} askPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AskPasswordApi
     */
    public setNewPassword(authorization: string, askPasswordRequest: AskPasswordRequest, options?: AxiosRequestConfig) {
        return AskPasswordApiFp(this.configuration).setNewPassword(authorization, askPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AskSmsOtpApi - axios parameter creator
 * @export
 */
export const AskSmsOtpApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Check if the OTP provided by the user is the same that was previously sent to him.
         * @summary Confirms the SMS OTP sent
         * @param {string} authorization identify the registration request for this operation. It should be sent as a Bearer token although no OAuth authorization server produces it, nor is processed by any OAuth flow.
         * @param {AskSmsOtpRequest} askSmsOtpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmSmsOtp: async (authorization: string, askSmsOtpRequest: AskSmsOtpRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('confirmSmsOtp', 'authorization', authorization)
            // verify required parameter 'askSmsOtpRequest' is not null or undefined
            assertParamExists('confirmSmsOtp', 'askSmsOtpRequest', askSmsOtpRequest)
            const localVarPath = `/api/registration-req/sms-otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(askSmsOtpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sends a new SMS OTP to the user telephone number
         * @summary send a new SMS OTP sent
         * @param {string} authorization identify the registration request for this operation. It should be sent as a Bearer token although no OAuth authorization server produces it, nor is processed by any OAuth flow.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNewSmsOtp: async (authorization: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('sendNewSmsOtp', 'authorization', authorization)
            const localVarPath = `/api/registration-req/sms-otp/send-new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AskSmsOtpApi - functional programming interface
 * @export
 */
export const AskSmsOtpApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AskSmsOtpApiAxiosParamCreator(configuration)
    return {
        /**
         * Check if the OTP provided by the user is the same that was previously sent to him.
         * @summary Confirms the SMS OTP sent
         * @param {string} authorization identify the registration request for this operation. It should be sent as a Bearer token although no OAuth authorization server produces it, nor is processed by any OAuth flow.
         * @param {AskSmsOtpRequest} askSmsOtpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmSmsOtp(authorization: string, askSmsOtpRequest: AskSmsOtpRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmSmsOtp(authorization, askSmsOtpRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sends a new SMS OTP to the user telephone number
         * @summary send a new SMS OTP sent
         * @param {string} authorization identify the registration request for this operation. It should be sent as a Bearer token although no OAuth authorization server produces it, nor is processed by any OAuth flow.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNewSmsOtp(authorization: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendNewSmsOtp(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AskSmsOtpApi - factory interface
 * @export
 */
export const AskSmsOtpApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AskSmsOtpApiFp(configuration)
    return {
        /**
         * Check if the OTP provided by the user is the same that was previously sent to him.
         * @summary Confirms the SMS OTP sent
         * @param {string} authorization identify the registration request for this operation. It should be sent as a Bearer token although no OAuth authorization server produces it, nor is processed by any OAuth flow.
         * @param {AskSmsOtpRequest} askSmsOtpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmSmsOtp(authorization: string, askSmsOtpRequest: AskSmsOtpRequest, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.confirmSmsOtp(authorization, askSmsOtpRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Sends a new SMS OTP to the user telephone number
         * @summary send a new SMS OTP sent
         * @param {string} authorization identify the registration request for this operation. It should be sent as a Bearer token although no OAuth authorization server produces it, nor is processed by any OAuth flow.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNewSmsOtp(authorization: string, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.sendNewSmsOtp(authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AskSmsOtpApi - object-oriented interface
 * @export
 * @class AskSmsOtpApi
 * @extends {BaseAPI}
 */
export class AskSmsOtpApi extends BaseAPI {
    /**
     * Check if the OTP provided by the user is the same that was previously sent to him.
     * @summary Confirms the SMS OTP sent
     * @param {string} authorization identify the registration request for this operation. It should be sent as a Bearer token although no OAuth authorization server produces it, nor is processed by any OAuth flow.
     * @param {AskSmsOtpRequest} askSmsOtpRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AskSmsOtpApi
     */
    public confirmSmsOtp(authorization: string, askSmsOtpRequest: AskSmsOtpRequest, options?: AxiosRequestConfig) {
        return AskSmsOtpApiFp(this.configuration).confirmSmsOtp(authorization, askSmsOtpRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sends a new SMS OTP to the user telephone number
     * @summary send a new SMS OTP sent
     * @param {string} authorization identify the registration request for this operation. It should be sent as a Bearer token although no OAuth authorization server produces it, nor is processed by any OAuth flow.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AskSmsOtpApi
     */
    public sendNewSmsOtp(authorization: string, options?: AxiosRequestConfig) {
        return AskSmsOtpApiFp(this.configuration).sendNewSmsOtp(authorization, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RegCompInternalOperationsApi - axios parameter creator
 * @export
 */
export const RegCompInternalOperationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Allows to start a registration process from information collected by Atupri\'s Service center
         * @param {StartServiceCenterRequest} startServiceCenterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startServiceCenterRegistration: async (startServiceCenterRequest: StartServiceCenterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startServiceCenterRequest' is not null or undefined
            assertParamExists('startServiceCenterRegistration', 'startServiceCenterRequest', startServiceCenterRequest)
            const localVarPath = `/api-internal/registration-req/sc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startServiceCenterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Allows to start a registration process for a new Atupri customer
         * @param {StartNewCustomerRequest} startNewCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startServiceCenterRegistration1: async (startNewCustomerRequest: StartNewCustomerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startNewCustomerRequest' is not null or undefined
            assertParamExists('startServiceCenterRegistration1', 'startNewCustomerRequest', startNewCustomerRequest)
            const localVarPath = `/api-internal/registration-req/new-customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startNewCustomerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegCompInternalOperationsApi - functional programming interface
 * @export
 */
export const RegCompInternalOperationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegCompInternalOperationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Allows to start a registration process from information collected by Atupri\'s Service center
         * @param {StartServiceCenterRequest} startServiceCenterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startServiceCenterRegistration(startServiceCenterRequest: StartServiceCenterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startServiceCenterRegistration(startServiceCenterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Allows to start a registration process for a new Atupri customer
         * @param {StartNewCustomerRequest} startNewCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startServiceCenterRegistration1(startNewCustomerRequest: StartNewCustomerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startServiceCenterRegistration1(startNewCustomerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegCompInternalOperationsApi - factory interface
 * @export
 */
export const RegCompInternalOperationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegCompInternalOperationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Allows to start a registration process from information collected by Atupri\'s Service center
         * @param {StartServiceCenterRequest} startServiceCenterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startServiceCenterRegistration(startServiceCenterRequest: StartServiceCenterRequest, options?: any): AxiosPromise<StartResponse> {
            return localVarFp.startServiceCenterRegistration(startServiceCenterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Allows to start a registration process for a new Atupri customer
         * @param {StartNewCustomerRequest} startNewCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startServiceCenterRegistration1(startNewCustomerRequest: StartNewCustomerRequest, options?: any): AxiosPromise<StartResponse> {
            return localVarFp.startServiceCenterRegistration1(startNewCustomerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegCompInternalOperationsApi - object-oriented interface
 * @export
 * @class RegCompInternalOperationsApi
 * @extends {BaseAPI}
 */
export class RegCompInternalOperationsApi extends BaseAPI {
    /**
     * 
     * @summary Allows to start a registration process from information collected by Atupri\'s Service center
     * @param {StartServiceCenterRequest} startServiceCenterRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegCompInternalOperationsApi
     */
    public startServiceCenterRegistration(startServiceCenterRequest: StartServiceCenterRequest, options?: AxiosRequestConfig) {
        return RegCompInternalOperationsApiFp(this.configuration).startServiceCenterRegistration(startServiceCenterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Allows to start a registration process for a new Atupri customer
     * @param {StartNewCustomerRequest} startNewCustomerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegCompInternalOperationsApi
     */
    public startServiceCenterRegistration1(startNewCustomerRequest: StartNewCustomerRequest, options?: AxiosRequestConfig) {
        return RegCompInternalOperationsApiFp(this.configuration).startServiceCenterRegistration1(startNewCustomerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ResumeRegistrationApi - axios parameter creator
 * @export
 */
export const ResumeRegistrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Resumes a registration request identified by a token
         * @param {ResumeRegistrationRequest} resumeRegistrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeRegistration: async (resumeRegistrationRequest: ResumeRegistrationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resumeRegistrationRequest' is not null or undefined
            assertParamExists('resumeRegistration', 'resumeRegistrationRequest', resumeRegistrationRequest)
            const localVarPath = `/api/registration-req/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resumeRegistrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResumeRegistrationApi - functional programming interface
 * @export
 */
export const ResumeRegistrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ResumeRegistrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Resumes a registration request identified by a token
         * @param {ResumeRegistrationRequest} resumeRegistrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resumeRegistration(resumeRegistrationRequest: ResumeRegistrationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resumeRegistration(resumeRegistrationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ResumeRegistrationApi - factory interface
 * @export
 */
export const ResumeRegistrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ResumeRegistrationApiFp(configuration)
    return {
        /**
         * 
         * @summary Resumes a registration request identified by a token
         * @param {ResumeRegistrationRequest} resumeRegistrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeRegistration(resumeRegistrationRequest: ResumeRegistrationRequest, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.resumeRegistration(resumeRegistrationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ResumeRegistrationApi - object-oriented interface
 * @export
 * @class ResumeRegistrationApi
 * @extends {BaseAPI}
 */
export class ResumeRegistrationApi extends BaseAPI {
    /**
     * 
     * @summary Resumes a registration request identified by a token
     * @param {ResumeRegistrationRequest} resumeRegistrationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResumeRegistrationApi
     */
    public resumeRegistration(resumeRegistrationRequest: ResumeRegistrationRequest, options?: AxiosRequestConfig) {
        return ResumeRegistrationApiFp(this.configuration).resumeRegistration(resumeRegistrationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StartSelfRegistrationApi - axios parameter creator
 * @export
 */
export const StartSelfRegistrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Allows to start a self service registration process
         * @param {StartSelfServiceRequest} startSelfServiceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startSelfService: async (startSelfServiceRequest: StartSelfServiceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startSelfServiceRequest' is not null or undefined
            assertParamExists('startSelfService', 'startSelfServiceRequest', startSelfServiceRequest)
            const localVarPath = `/api/registration-req/self-service`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startSelfServiceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StartSelfRegistrationApi - functional programming interface
 * @export
 */
export const StartSelfRegistrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StartSelfRegistrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Allows to start a self service registration process
         * @param {StartSelfServiceRequest} startSelfServiceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startSelfService(startSelfServiceRequest: StartSelfServiceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startSelfService(startSelfServiceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StartSelfRegistrationApi - factory interface
 * @export
 */
export const StartSelfRegistrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StartSelfRegistrationApiFp(configuration)
    return {
        /**
         * 
         * @summary Allows to start a self service registration process
         * @param {StartSelfServiceRequest} startSelfServiceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startSelfService(startSelfServiceRequest: StartSelfServiceRequest, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.startSelfService(startSelfServiceRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StartSelfRegistrationApi - object-oriented interface
 * @export
 * @class StartSelfRegistrationApi
 * @extends {BaseAPI}
 */
export class StartSelfRegistrationApi extends BaseAPI {
    /**
     * 
     * @summary Allows to start a self service registration process
     * @param {StartSelfServiceRequest} startSelfServiceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StartSelfRegistrationApi
     */
    public startSelfService(startSelfServiceRequest: StartSelfServiceRequest, options?: AxiosRequestConfig) {
        return StartSelfRegistrationApiFp(this.configuration).startSelfService(startSelfServiceRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StartUserMigrationApi - axios parameter creator
 * @export
 */
export const StartUserMigrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Allows to start a registration process to migrate a user account from AD to Nevis but asks in a first step for the email address
         * @param {ContinueUserMigrationRequest} continueUserMigrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continueUserMigrationWithNewEmail: async (continueUserMigrationRequest: ContinueUserMigrationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'continueUserMigrationRequest' is not null or undefined
            assertParamExists('continueUserMigrationWithNewEmail', 'continueUserMigrationRequest', continueUserMigrationRequest)
            const localVarPath = `/api/registration-req/continuemigration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(continueUserMigrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Allows to start a registration process to migrate a user account from AD to Nevis
         * @param {StartUserMigrationRequest} startUserMigrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startUserMigration: async (startUserMigrationRequest: StartUserMigrationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startUserMigrationRequest' is not null or undefined
            assertParamExists('startUserMigration', 'startUserMigrationRequest', startUserMigrationRequest)
            const localVarPath = `/api/registration-req/migration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startUserMigrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Allows to start a registration process to migrate a user account from AD to Nevis but asks in a first step for the email address
         * @param {StartUserMigrationRequest} startUserMigrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startUserMigrationWithNewEmail: async (startUserMigrationRequest: StartUserMigrationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startUserMigrationRequest' is not null or undefined
            assertParamExists('startUserMigrationWithNewEmail', 'startUserMigrationRequest', startUserMigrationRequest)
            const localVarPath = `/api/registration-req/emailmigration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startUserMigrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StartUserMigrationApi - functional programming interface
 * @export
 */
export const StartUserMigrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StartUserMigrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Allows to start a registration process to migrate a user account from AD to Nevis but asks in a first step for the email address
         * @param {ContinueUserMigrationRequest} continueUserMigrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async continueUserMigrationWithNewEmail(continueUserMigrationRequest: ContinueUserMigrationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.continueUserMigrationWithNewEmail(continueUserMigrationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Allows to start a registration process to migrate a user account from AD to Nevis
         * @param {StartUserMigrationRequest} startUserMigrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startUserMigration(startUserMigrationRequest: StartUserMigrationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startUserMigration(startUserMigrationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Allows to start a registration process to migrate a user account from AD to Nevis but asks in a first step for the email address
         * @param {StartUserMigrationRequest} startUserMigrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startUserMigrationWithNewEmail(startUserMigrationRequest: StartUserMigrationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startUserMigrationWithNewEmail(startUserMigrationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StartUserMigrationApi - factory interface
 * @export
 */
export const StartUserMigrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StartUserMigrationApiFp(configuration)
    return {
        /**
         * 
         * @summary Allows to start a registration process to migrate a user account from AD to Nevis but asks in a first step for the email address
         * @param {ContinueUserMigrationRequest} continueUserMigrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continueUserMigrationWithNewEmail(continueUserMigrationRequest: ContinueUserMigrationRequest, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.continueUserMigrationWithNewEmail(continueUserMigrationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Allows to start a registration process to migrate a user account from AD to Nevis
         * @param {StartUserMigrationRequest} startUserMigrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startUserMigration(startUserMigrationRequest: StartUserMigrationRequest, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.startUserMigration(startUserMigrationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Allows to start a registration process to migrate a user account from AD to Nevis but asks in a first step for the email address
         * @param {StartUserMigrationRequest} startUserMigrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startUserMigrationWithNewEmail(startUserMigrationRequest: StartUserMigrationRequest, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.startUserMigrationWithNewEmail(startUserMigrationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StartUserMigrationApi - object-oriented interface
 * @export
 * @class StartUserMigrationApi
 * @extends {BaseAPI}
 */
export class StartUserMigrationApi extends BaseAPI {
    /**
     * 
     * @summary Allows to start a registration process to migrate a user account from AD to Nevis but asks in a first step for the email address
     * @param {ContinueUserMigrationRequest} continueUserMigrationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StartUserMigrationApi
     */
    public continueUserMigrationWithNewEmail(continueUserMigrationRequest: ContinueUserMigrationRequest, options?: AxiosRequestConfig) {
        return StartUserMigrationApiFp(this.configuration).continueUserMigrationWithNewEmail(continueUserMigrationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Allows to start a registration process to migrate a user account from AD to Nevis
     * @param {StartUserMigrationRequest} startUserMigrationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StartUserMigrationApi
     */
    public startUserMigration(startUserMigrationRequest: StartUserMigrationRequest, options?: AxiosRequestConfig) {
        return StartUserMigrationApiFp(this.configuration).startUserMigration(startUserMigrationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Allows to start a registration process to migrate a user account from AD to Nevis but asks in a first step for the email address
     * @param {StartUserMigrationRequest} startUserMigrationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StartUserMigrationApi
     */
    public startUserMigrationWithNewEmail(startUserMigrationRequest: StartUserMigrationRequest, options?: AxiosRequestConfig) {
        return StartUserMigrationApiFp(this.configuration).startUserMigrationWithNewEmail(startUserMigrationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UamOperationsApi - axios parameter creator
 * @export
 */
export const UamOperationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Changes the nevis password for the current logged in user
         * @param {ChangePasswordRequest} changePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (changePasswordRequest: ChangePasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changePasswordRequest' is not null or undefined
            assertParamExists('changePassword', 'changePasswordRequest', changePasswordRequest)
            const localVarPath = `/api-uam/pwd`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change the mobile phone number in Nevis Account if the provided SSM Otp is valid
         * @param {ConfirmNewMobileRequest} confirmNewMobileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmNewMobilePhone: async (confirmNewMobileRequest: ConfirmNewMobileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'confirmNewMobileRequest' is not null or undefined
            assertParamExists('confirmNewMobilePhone', 'confirmNewMobileRequest', confirmNewMobileRequest)
            const localVarPath = `/api-uam/mobile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmNewMobileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the identification level of the IAL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIalLevel: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-uam/ial-level`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary resends a new SMS Otp to the previous given phone number
         * @param {ResendSmsOtpRequest} resendSmsOtpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendNewSmsOtp: async (resendSmsOtpRequest: ResendSmsOtpRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resendSmsOtpRequest' is not null or undefined
            assertParamExists('resendNewSmsOtp', 'resendSmsOtpRequest', resendSmsOtpRequest)
            const localVarPath = `/api-uam/mobile/resend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resendSmsOtpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns the Nevis information about the current logged user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAccountCurrentInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-uam`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a Sms Otp to the proposed new mobile phone to confirm the phone ownership
         * @param {SendSmsOtpRequest} sendSmsOtpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSmsOtp: async (sendSmsOtpRequest: SendSmsOtpRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendSmsOtpRequest' is not null or undefined
            assertParamExists('sendSmsOtp', 'sendSmsOtpRequest', sendSmsOtpRequest)
            const localVarPath = `/api-uam/mobile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendSmsOtpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UamOperationsApi - functional programming interface
 * @export
 */
export const UamOperationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UamOperationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Changes the nevis password for the current logged in user
         * @param {ChangePasswordRequest} changePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(changePasswordRequest: ChangePasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(changePasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change the mobile phone number in Nevis Account if the provided SSM Otp is valid
         * @param {ConfirmNewMobileRequest} confirmNewMobileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmNewMobilePhone(confirmNewMobileRequest: ConfirmNewMobileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmNewMobilePhone(confirmNewMobileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the identification level of the IAL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIalLevel(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentificationLevel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIalLevel(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary resends a new SMS Otp to the previous given phone number
         * @param {ResendSmsOtpRequest} resendSmsOtpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendNewSmsOtp(resendSmsOtpRequest: ResendSmsOtpRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendNewSmsOtp(resendSmsOtpRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns the Nevis information about the current logged user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveAccountCurrentInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentAccountInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveAccountCurrentInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a Sms Otp to the proposed new mobile phone to confirm the phone ownership
         * @param {SendSmsOtpRequest} sendSmsOtpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendSmsOtp(sendSmsOtpRequest: SendSmsOtpRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendSmsOtpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendSmsOtp(sendSmsOtpRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UamOperationsApi - factory interface
 * @export
 */
export const UamOperationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UamOperationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Changes the nevis password for the current logged in user
         * @param {ChangePasswordRequest} changePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(changePasswordRequest: ChangePasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.changePassword(changePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change the mobile phone number in Nevis Account if the provided SSM Otp is valid
         * @param {ConfirmNewMobileRequest} confirmNewMobileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmNewMobilePhone(confirmNewMobileRequest: ConfirmNewMobileRequest, options?: any): AxiosPromise<void> {
            return localVarFp.confirmNewMobilePhone(confirmNewMobileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the identification level of the IAL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIalLevel(options?: any): AxiosPromise<IdentificationLevel> {
            return localVarFp.getIalLevel(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary resends a new SMS Otp to the previous given phone number
         * @param {ResendSmsOtpRequest} resendSmsOtpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendNewSmsOtp(resendSmsOtpRequest: ResendSmsOtpRequest, options?: any): AxiosPromise<void> {
            return localVarFp.resendNewSmsOtp(resendSmsOtpRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns the Nevis information about the current logged user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAccountCurrentInfo(options?: any): AxiosPromise<CurrentAccountInfoResponse> {
            return localVarFp.retrieveAccountCurrentInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a Sms Otp to the proposed new mobile phone to confirm the phone ownership
         * @param {SendSmsOtpRequest} sendSmsOtpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSmsOtp(sendSmsOtpRequest: SendSmsOtpRequest, options?: any): AxiosPromise<SendSmsOtpResponse> {
            return localVarFp.sendSmsOtp(sendSmsOtpRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UamOperationsApi - object-oriented interface
 * @export
 * @class UamOperationsApi
 * @extends {BaseAPI}
 */
export class UamOperationsApi extends BaseAPI {
    /**
     * 
     * @summary Changes the nevis password for the current logged in user
     * @param {ChangePasswordRequest} changePasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UamOperationsApi
     */
    public changePassword(changePasswordRequest: ChangePasswordRequest, options?: AxiosRequestConfig) {
        return UamOperationsApiFp(this.configuration).changePassword(changePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change the mobile phone number in Nevis Account if the provided SSM Otp is valid
     * @param {ConfirmNewMobileRequest} confirmNewMobileRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UamOperationsApi
     */
    public confirmNewMobilePhone(confirmNewMobileRequest: ConfirmNewMobileRequest, options?: AxiosRequestConfig) {
        return UamOperationsApiFp(this.configuration).confirmNewMobilePhone(confirmNewMobileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the identification level of the IAL
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UamOperationsApi
     */
    public getIalLevel(options?: AxiosRequestConfig) {
        return UamOperationsApiFp(this.configuration).getIalLevel(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary resends a new SMS Otp to the previous given phone number
     * @param {ResendSmsOtpRequest} resendSmsOtpRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UamOperationsApi
     */
    public resendNewSmsOtp(resendSmsOtpRequest: ResendSmsOtpRequest, options?: AxiosRequestConfig) {
        return UamOperationsApiFp(this.configuration).resendNewSmsOtp(resendSmsOtpRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns the Nevis information about the current logged user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UamOperationsApi
     */
    public retrieveAccountCurrentInfo(options?: AxiosRequestConfig) {
        return UamOperationsApiFp(this.configuration).retrieveAccountCurrentInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a Sms Otp to the proposed new mobile phone to confirm the phone ownership
     * @param {SendSmsOtpRequest} sendSmsOtpRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UamOperationsApi
     */
    public sendSmsOtp(sendSmsOtpRequest: SendSmsOtpRequest, options?: AxiosRequestConfig) {
        return UamOperationsApiFp(this.configuration).sendSmsOtp(sendSmsOtpRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


