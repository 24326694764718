import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Grid, GridItem, Spacer } from 'atupri-component-library/lib/helpers';
import { dataTestId } from '../../helper';
import { MinimalisticHeaderFooter } from '../MinimalisticHeaderFooter';

const StyledHeroImage = styled.div`
    background-repeat: no-repeat;
    background-size: 100%;
`;

const BasePage = ({ children }: BaseLoginProps) => {
    return (
        <MinimalisticHeaderFooter>
            <StyledHeroImage data-testid={dataTestId('heroImage')}>
                <Grid>
                    <GridItem size={12}>
                        <Spacer space="theta" />
                        {children}
                    </GridItem>
                </Grid>
            </StyledHeroImage>
        </MinimalisticHeaderFooter>
    );
};

export type BaseLoginProps = {
    children: ReactNode;
};

export default BasePage;
