
import {
    MIN_DIGITS,
    MIN_LENGTH,
    MIN_LOWERCASE,
    MIN_SPECIAL_CHAR,
    MIN_UPPERCASE,
    NUMBER_OF_CRITERIA
} from "../../model/Password";

export enum ValidationState {
    INITIAL,
    SUCCESS,
    ERROR,
}

export type UserInformationForPwd = {
    insuredNr: string;
    firstName: string;
    lastName: string;
}

/**
 * Validator checks if the password criteria are met
 * and pushes the result to the internal React state
 * @param message the error text, which should be shown
 * @return {(VALID|message)} VALID or the error text
 */
export const passwordCriteriaMetValidator = (message: string, passwordInformation: UserInformationForPwd, setPasswordValidation: any) => (value: string) => {
    /* istanbul ignore else */
    if (value) {
        //regex pattern \p{L} is described here: https://www.regular-expressions.info/unicode.html#category
        const upper = value.match(/[\p{Lu}]/gu),
            lower = value.match(/[\p{Ll}]/gu),
            number = value.match(/[0-9]/g),
            special = value.match(/[^\p{L}0-9]/gu);

        // number of occurrences within the password
        const upperLength = upper ? upper.length : 0;
        const lowerLength = lower ? lower.length : 0;
        const numberLength = number ? number.length : 0;
        const specialLength = special ? special.length : 0;

        // build the actual state
        const actualState = {
            minChar: {
                validationState: value.length >= MIN_LENGTH ? ValidationState.SUCCESS : ValidationState.ERROR,
                count: value.length,
            },
            minDigits: {
                validationState: numberLength >= MIN_DIGITS ? ValidationState.SUCCESS : ValidationState.ERROR,
                count: numberLength,
            },
            minUpperCase: {
                validationState: upperLength >= MIN_UPPERCASE ? ValidationState.SUCCESS : ValidationState.ERROR,
                count: upperLength,
            },
            minLowerCase: {
                validationState: lowerLength >= MIN_LOWERCASE ? ValidationState.SUCCESS : ValidationState.ERROR,
                count: lowerLength,
            },
            minSpecialChar: {
                validationState:
                    specialLength >= MIN_SPECIAL_CHAR ? ValidationState.SUCCESS : ValidationState.ERROR,
                count: specialLength,
            },
            trivial: {validationState: trivialPasswordCheck(value, passwordInformation)},
        };

        // take the previous state and update it with the actual state
        setPasswordValidation((prevState: any) => ({
            ...prevState,
            ...actualState,
        }));

        // check if all of the six criteria have a validation status of SUCCESS
        const allCriteriaMet =
            Object.values(actualState).filter((item) => item.validationState === ValidationState.SUCCESS).length ===
            NUMBER_OF_CRITERIA;

        return allCriteriaMet ? undefined : message;
    }
};

/**
 * Validator checks if the value of the input matches another value
 * @param originalField the field name of the field being validated against
 * @param message the error text, which should be shown
 * @return {(VALID|message)} VALID or the error text
 */
export const confirmationFieldValidator = (message: string, originalField: string) => (value: string, allValues: any) => {
    if (allValues[originalField] === value) {
        return undefined;
    }
    return message;
};

// helper function which compares the value against first name, last name and insured number
const trivialPasswordCheck = (value: string, passwordInformation: UserInformationForPwd) => {
    if (
        value
            .match(/\d+/g)
            ?.join('')
            .includes(passwordInformation?.insuredNr?.toString())
    )
        return ValidationState.ERROR;
    if (value.toLowerCase().includes(passwordInformation?.firstName?.toLowerCase())) return ValidationState.ERROR;
    if (value.toLowerCase().includes(passwordInformation?.lastName?.toLowerCase())) return ValidationState.ERROR;

    return ValidationState.SUCCESS;
};
