import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';
import process from 'process'
import { nextMonday} from 'date-fns';

// Helper function to solve the issue of i18next.language returning specific languages, e.g. de-CH.
// That creates incorrect link paths in Header and Footer.
export function getSimpleClientLanguage() {
	return i18next.language?.length > 1 ? i18next.language.slice(0, 2) : 'de';
}

// get the translation for a specific key in german (used for GTM calls)
export const getGermanTranslation = i18next.getFixedT('de');

export function getAtuprichHost(): string {
    const host = window.location.host;

    if (host.startsWith('localhost') || host.startsWith('daccount') || host.startsWith('duam')) {
        return 'https://datuprich.ocp-d.atupri.ch/';
    } else if (host.startsWith('taccount') || host.startsWith('tuam')) {
        return 'https://tatuprich.ocp-t.atupri.ch/';
    } else if (host.startsWith('iaccount') || host.startsWith('iuam')) {
        return 'https://iatuprich.atupri.ch/';
    } else {
        return 'https://atupri.ch/';
    }
}


// this function runs for all dates between the minDate and the maxDate.
// it will always disable Saturday (6) and Sunday (0) and the next Monday (1) if the current date is a weekend.
// it returns true for dates which should be disabled and false for enabled.
export const isWeekendDatepickerTile = ({ date }: any): boolean => {
	// get the date of today, for the isWeekend function
	const today = new Date();

	// use date-fns to get the date for the next monday
	const nextMondayDate = nextMonday(today);

	// returns true if the const today is between Friday after 17:00 and Sunday midnight
	const isWeekend = () => {
		// check only the upcoming weekend
		if (date < nextMondayDate) {
			if (
				// 5 is the index of Friday
				(today.getDay() === 5 && today.getHours() >= 17) ||
				// 6 is the index of Saturday
				today.getDay() === 6 ||
				// 0 is the index of Sunday
				today.getDay() === 0
			) {
				return true;
			}
		}
		return false;
	};

	return date.getDay() === 0 || date.getDay() === 6 || isWeekend();
};




export const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
};




export const dataTestId = (testId: string): string | undefined => (process?.env.JEST ? testId : undefined);

export { logger, callApi } from './logger';
