export const refineURL = () => {
    //get full URL
    let currURL = window.location.href; //get current address

    //Get the URL between what's after '/' and before '?'
    //1- get URL after'/'
    let afterDomain = currURL.substring(currURL.lastIndexOf('/') + 1);
    //2- get the part before '?'
    let beforeQueryString = afterDomain.split('?')[0];

    window.history.pushState({}, '', '/' + beforeQueryString);
};
