import { CountryCode, parsePhoneNumber } from 'libphonenumber-js/max';
import { required } from './ValidationCommons';
import i18next from 'i18next';
import { composeValidators, validPhoneNumber } from 'atupri-component-library/lib/core/utils/Validators';

export const arrSpecialCountry = ['AG', 'BS', 'BB', 'CA', 'DM', 'DO', 'GD', 'JM', 'KN', 'LC', 'VC', 'TT'];

const allowedPhoneTypes = ['MOBILE' /*, 'FIXED_LINE_OR_MOBILE', 'UAN'*/];

export const validatePhoneNumber =
    (message: string, allowedTypes: string[], countryCode = '', iso = 'CH') =>
    (value: string) => {
        if (value) {
            const tmpValue = value.replace(countryCode, '').trim();

            const phoneNumber = parsePhoneNumber(tmpValue, iso as CountryCode); // create object of type PhoneNumber from libphonenumber-js. See: https://gitlab.com/catamphetamine/libphonenumber-js/-/blob/master/types.d.ts#L69
            const isValidNumber = phoneNumber.isValid(); // validates both phone number length and the actual phone number digits
            const isValidType = allowedTypes.includes(phoneNumber.getType() as string); // returns true if type is on the allowed list

            return isValidNumber && isValidType ? undefined : message;
        }
        return undefined;
    };

// allow only digits, "+" and spaces in the input field (regex uses negative character classes)
export const formatPhoneNumber = (value: string) => (value ? value.replace(/[^0-9+ ]/, '') : '');

export const PhoneNumberValidatorFn: (value: string, countryCode?: string, iso?: string) => any = (
    value,
    countryCode,
    iso
) => {
    const tmp = composeValidators(
        required(
            i18next.t(
                'uam.changePhoneNumberScreen.validations.phoneRequiredError',
                'Bitte geben Sie eine gültige Mobilenummer an.'
            )
        ),
        validPhoneNumber(
            i18next.t(
                'uam.changePhoneNumberScreen.validations.invalidPhoneError',
                'Bitte geben Sie eine gültige Mobilenummer an.'
            )
        ),
        validatePhoneNumber(
            i18next.t(
                'uam.changePhoneNumberScreen.validations.invalidPhoneSyntaxError',
                'Bitte geben Sie eine gültige Mobilenummer an.'
            ),
            allowedPhoneTypes,
            countryCode,
            iso
        )
    );

    return tmp(value);
};

/**
 * Converts the phone number format from the frontend to the API format.
 * @param phoneNumber
 * @returns
 */
export const convertPhoneFormatFromApiToFrontend = (phoneNumber: string) => {
    const mobileInfo = parsePhoneNumber(phoneNumber);

    if (arrSpecialCountry.includes(mobileInfo.country?.toString() ?? '')) {
        return {
            iso: mobileInfo.country?.toString() ?? '',
            countryCode: `${mobileInfo.number.slice(0, 2)}-${mobileInfo.number.slice(2, 5)}`,
            phoneNumber: mobileInfo.number.slice(5),
        };
    } else {
        return {
            iso: mobileInfo.country?.toString() ?? '',
            countryCode: mobileInfo.countryCallingCode ? `+${mobileInfo.countryCallingCode}` : '',
            phoneNumber: mobileInfo.nationalNumber ?? '',
        };
    }
};

export const sanitizePhoneNumber = (phoneNumber: string) => {
    return phoneNumber.replace(/^0+/, '');
};
