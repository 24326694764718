import { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { ExceptionResponse } from '../generated';

export type ErrorContextType = {
	errorData?: ExceptionResponse;
};

export const ErrorContext = createContext<ErrorContextType>({
	errorData: undefined,
});

export const ErrorProvider = (props: any) => {
	// init error state
	const [errorData, setErrorData] = useState<ExceptionResponse>();

	// intercept the error code and set it to the state
	useEffect(() => {
		axios.interceptors.response.use(
			(response) => response,
			async (error) => {
				setErrorData(error.response.data);
				return Promise.reject(error);
			}
		);
	}, []);

	return <ErrorContext.Provider value={{ errorData }} {...props} />;
};

export const useErrorContext = () => useContext(ErrorContext);
