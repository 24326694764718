import { Field } from 'react-final-form';
import { FieldValidator } from 'final-form';
import { GridItem } from 'atupri-component-library/lib/helpers';
import { Input } from 'atupri-component-library/lib/primitives';
import { dataTestId } from '../../../helper';

const GridItemInput = ({
    beforeSubmit,
    format,
    gridItemSize = { zero: 'full', medium: 6 },
    inputName,
    type,
    label,
    validators,
    maxLength,
    autoComplete,
    inputRightIcon,
    inputRightTooltip,
    passwordrules,
    readOnly,
    onInputChange,
}: AbstractTextInputProps) => {
    return (
        <GridItem size={gridItemSize}>
            <Field beforeSubmit={beforeSubmit} format={format} label={label} name={inputName} validate={validators}>
                {({ label, input, meta }) => (
                    <Input
                        data-testid={dataTestId(input.name)}
                        errorText={meta.touched && meta.error}
                        id={inputName}
                        type={type}
                        label={label}
                        name={input.name}
                        onBlur={input.onBlur}
                        onChange={(value: any) => {
                            if (onInputChange) {
                                onInputChange(value);
                            }
                            return input.onChange(value);
                        }}
                        value={input.value}
                        maxLength={maxLength}
                        autoComplete={autoComplete}
                        inputRightIcon={inputRightIcon}
                        inputRightTooltip={inputRightTooltip}
                        passwordrules={passwordrules}
                        readOnly={readOnly}
                    />
                )}
            </Field>
        </GridItem>
    );
};

type AbstractTextInputProps = {
    beforeSubmit?: () => void | boolean;
    format?: (value: string, name: string) => string;
    gridItemSize?: any;
    label: string;
    inputName: string;
    type?: string;
    validators?: FieldValidator<any>;
    autoComplete?: string;
    maxLength?: number;
    inputRightIcon?: any;
    inputRightTooltip?: any;
    passwordrules?: string;
    readOnly?: boolean;
    onInputChange?: (event: any) => void;
};

export default GridItemInput;
