export const getAccessToken = (): string | undefined => {
    let token;
    let tokenType;
    for (let key in sessionStorage) {
        if (key.includes('oidc.user') && sessionStorage.getItem(key)) {
            token = JSON.parse(sessionStorage.getItem(key)!).access_token;
            tokenType = JSON.parse(sessionStorage.getItem(key)!).token_type;
            break;
        }
    }
    return token && tokenType ? tokenType + ' ' + token : undefined;
};
