import { Box, Grid, GridItem, Spacer } from 'atupri-component-library/lib/helpers';
import i18next from 'i18next';
import { GridItemInput } from '../../components/FormControls';
import { Form } from 'react-final-form';
import styled from '@emotion/styled';
import { H2, Icon, P1 } from 'atupri-component-library/lib/primitives';
import { colors } from 'atupri-component-library/lib/core/styles/theme/default';
import { rem } from 'polished';
import { selectApplicationInfo, setLastUserInfoUpdate } from '../../redux/application.slide';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { CustomerInfo, selectCustomerInfo, update } from '../../redux/customer-info.slide';
import { useEffect, useMemo, useState } from 'react';
import ChangeEmailScreen from '../ChangeEmail/ChangeEmailScreen';
import ChangeMobileNumberScreen from '../ChangeMobileNumber/ChangeMobileNumberScreen';
import ChangePasswordScreen from '../ChangePassword/ChangePasswordScreen';
import { userInfoService } from '../../service';
import { convertPhoneFormatFromApiToFrontend } from '../../helper/phoneNumber';
import { callApi } from '../../helper';
import Loader from '../../components/Loader';

const StyledTitle = styled(H2)`
    font-size: 2.5rem !important;
    line-height: inherit;
`;

const StyledAvatar = styled.div`
    display: inline-flex;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: ${colors.blue300};
    color: ${colors.blue800};
    height: ${rem(50)};
    align-items: center;
    justify-content: center;
    margin-right: ${rem(16)};
`;

const StyledGridItemFlex = styled(GridItem)`
    display: flex;
`;

const StyledCenterGrid = styled(Grid)`
    justify-content: center;
`;

const StyledLink = styled.a`
    text-decoration: underline;
    color: ${colors.blue800};
`

const UserInfoScreen = () => {
    const dispatch: AppDispatch = useDispatch();
    const applicationInfo = useSelector(selectApplicationInfo);
    const customerInfo = useSelector(selectCustomerInfo);

    const [showDialog, setShowDialog] = useState(false);
    const [dialogEditInfo, setDialogEditInfo] = useState<'email' | 'password' | 'mobile' | undefined>(undefined);

    useEffect(() => {
        callApi('RetrieveAccountInfo', userInfoService.retrieveAccountInfo(), (data) => {
            dispatch(
                update({
                    ...data,
                    mobileInfo: data.mobilePhoneNumber ? convertPhoneFormatFromApiToFrontend(data.mobilePhoneNumber) : null,
                } as CustomerInfo)
            );
        });
    }, [dispatch]);

    const StyledDialogDiv = styled.div`
        width: min(100% - 40px, 960px);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    `;

    useEffect(() => {
        if (applicationInfo?.lastUserInfoUpdate) {
            setShowDialog(false);
        }
    }, [applicationInfo?.lastUserInfoUpdate]);

    const StyledDialogOverlay = styled.div`
        display: ${showDialog ? 'flex' : 'none'};
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.7);
        justify-content: center;
        align-items: center;
        z-index: 1000;
    `;

    const MessageBoxAfterPasswordChange = () => {
        return (
            <Box padding="regular" shadow="ctaBlueHover" borderColor="success" borderRadius={4} background="successBg">
                <Grid>
                    <Icon iconName="check_outlined" style={{ marginTop: rem(4) }} />
                    <GridItem>
                        <strong>{i18next.t<string>('uam.userInfoScreen.messageBox.password.title', "Passwort wurde geändert")}</strong> <br />
                        {i18next.t<string>('uam.userInfoScreen.messageBox.password.text', "Ihr Passwort wurde erfolgreich geändert, sie werden zum Login automatisch weitergeleitet")}
                    </GridItem>
                </Grid>
            </Box>
        );
    };

    const MessageBoxAfterEmailChange = () => {
        return (
            <Box padding="regular" shadow="ctaBlueHover" borderColor="success" borderRadius={4} background="successBg">
                <Grid>
                    <Icon iconName="check_outlined" style={{ marginTop: rem(4) }} />
                    <GridItem>
                        <strong>{i18next.t<string>('uam.userInfoScreen.messageBox.email.title', "Mailadresse wurde geändert")}</strong> <br />
                        {i18next.t<string>('uam.userInfoScreen.messageBox.email.text', "Ihre Mailadresse wurde erfolgreich geändert")}
                    </GridItem>
                </Grid>
            </Box>
        );
    };

    const MessageBoxAfterPhoneChange = () => {
        return (
            <Box padding="regular" shadow="ctaBlueHover" borderColor="success" borderRadius={4} background="successBg">
                <Grid>
                    <Icon iconName="check_outlined" style={{ marginTop: rem(4) }} />
                    <GridItem>
                        <strong>{i18next.t<string>('uam.userInfoScreen.messageBox.mobile.title', "Mobiletelefonnummer wurde geändert")}</strong> <br />
                        {i18next.t<string>('uam.userInfoScreen.messageBox.mobile.text', "Ihre Mobiletelefonnummer wurde erfolgreich geändert")}
                    </GridItem>
                </Grid>
            </Box>
        );
    };

    const displayMessageBox = () => {
        if (applicationInfo?.lastUserInfoUpdate) {
            setTimeout(() => {
                // Hide message box after 5 seconds
                dispatch(setLastUserInfoUpdate(undefined));
            }, 5000);
        }

        switch (applicationInfo?.lastUserInfoUpdate) {
            case 'email':
                return <MessageBoxAfterEmailChange />;
            case 'mobile':
                return <MessageBoxAfterPhoneChange />;
            case 'password':
                return <MessageBoxAfterPasswordChange />;
            default:
                return null;
        }
    };

    const handleCancelDialog = () => {
        setDialogEditInfo(undefined);
        setShowDialog(false);
    };

    const showDialogEditInfo = (name: 'email' | 'mobile' | 'password') => {
        setDialogEditInfo(name);
        setShowDialog(true);
    };

    const displayDialog = useMemo(() => {
        switch (dialogEditInfo) {
            case 'email':
                return <ChangeEmailScreen onCancel={handleCancelDialog} />;
            case 'mobile':
                return <ChangeMobileNumberScreen onCancel={handleCancelDialog} />;
            case 'password':
                return <ChangePasswordScreen onCancel={handleCancelDialog} />;
            default:
                return null;
        }
    }, [dialogEditInfo]);

    return (
        <>
            {showDialog && (
                <StyledDialogOverlay>
                    <StyledDialogDiv>{displayDialog}</StyledDialogDiv>
                </StyledDialogOverlay>
            )}
            {applicationInfo?.loading && <Loader variant="cover" />}

            <StyledCenterGrid>
                <GridItem size={8}>{displayMessageBox()}</GridItem>

                <GridItem size={8}>
                    <GridItem size="full">
                        <StyledTitle>{i18next.t<string>('uam.userInfoScreen.intro')}</StyledTitle>
                        <Spacer space="delta" />
                    </GridItem>
                    <StyledGridItemFlex>
                        <StyledAvatar>
                            {[customerInfo?.firstName?.slice(0, 1), customerInfo?.lastName?.slice(0, 1)].join('')}
                        </StyledAvatar>
                        <div>
                            <div>{customerInfo?.firstName}</div>
                            <div>{customerInfo?.lastName}</div>
                        </div>
                    </StyledGridItemFlex>
                    <Spacer space="delta" />
                    <GridItem size="full">
                        <span>{i18next.t<string>('uam.userInfoScreen.userInfo')}</span>
                        <Spacer space="delta" />
                    </GridItem>
                    <Form
                        initialValues={{
                            email: customerInfo?.emailAddress ?? '',
                            mobile: customerInfo?.mobileInfo
                                ? customerInfo.mobileInfo.countryCode + customerInfo.mobileInfo.phoneNumber
                                : '',
                            password: '********',
                        }}
                        onSubmit={() => {}}
                        render={() => (
                            <form>
                                <Grid>
                                    <GridItem size="full">
                                        <GridItemInput
                                            gridItemSize="full"
                                            label={i18next.t('uam.userInfoScreen.emailLabel')}
                                            inputName="email"
                                            readOnly={true}
                                            type="email"
                                        />
                                        <Spacer space="delta" />
                                    </GridItem>
                                    <GridItem size="full">
                                        <GridItemInput
                                            gridItemSize="full"
                                            label={i18next.t('uam.userInfoScreen.mobilePhoneLabel')}
                                            inputName="mobile"
                                            readOnly={true}
                                            inputRightIcon={{
                                                iconName: 'pen',
                                                onClick: () => showDialogEditInfo('mobile'),
                                            }}
                                            type="text"
                                        />
                                        <Spacer space="delta" />
                                    </GridItem>
                                    <GridItem size="full">
                                        <GridItemInput
                                            gridItemSize="full"
                                            label={i18next.t('uam.userInfoScreen.passwordLabel')}
                                            inputName="password"
                                            readOnly={true}
                                            inputRightIcon={{
                                                iconName: 'pen',
                                                onClick: () => showDialogEditInfo('password'),
                                            }}
                                            type="password"
                                        />
                                    </GridItem>
                                </Grid>
                            </form>
                        )}
                    />
                    <GridItem size="full">
                        <Spacer space="iota" />
                        <P1>
                        <span>{i18next.t<string>('loginBase.faq.text.part1')} </span>
                            <StyledLink href={i18next.t<string>('loginBase.faq.link.href')} target='_blank'
                            >{i18next.t<string>('loginBase.faq.link.text')}</StyledLink>
                            <span> {i18next.t<string>('loginBase.faq.text.part2')}</span>
                        </P1>
                        <Spacer space="lambda" />
                    </GridItem>
                </GridItem>
            </StyledCenterGrid>
        </>
    );
};

export default UserInfoScreen;
