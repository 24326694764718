import styled from '@emotion/styled';
import { Box, Grid, GridItem, Spacer } from 'atupri-component-library/lib/helpers';
import i18next from 'i18next';
import { GridItemInput } from '../../components/FormControls';
import {
    AutocompleteMenu,
    AutocompleteMenuItem,
    Button,
    H2,
    Icon,
    Input,
} from 'atupri-component-library/lib/primitives';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { selectCustomerInfo, update } from '../../redux/customer-info.slide';
import { dataTestId } from '../../helper';
import { useEffect, useState } from 'react';
import { useCombobox } from 'downshift';
import countryCodes from '../../data/countrycodes.json';
import { formatPhoneNumber, PhoneNumberValidatorFn, sanitizePhoneNumber } from '../../helper/phoneNumber';
import { FormApi } from 'final-form';
import { composeValidators, minLength, required } from 'atupri-component-library/lib/core/utils/Validators';
import { rem } from 'polished';
import { LinkButton } from '../../components/FormButtons';
import { AppDispatch } from '../../redux/store';
import { setLastUserInfoUpdate } from '../../redux/application.slide';
import { userInfoService } from '../../service';
import { colors } from 'atupri-component-library/lib/core/styles/theme/default';

const StyledCenterGrid = styled(Grid)`
    justify-content: center;
`;

const StyledTitle = styled(H2)`
    font-size: 2.5rem !important;
    line-height: inherit;
`;

const StyledGridButtons = styled(GridItem)`
    display: flex;
    justify-content: end;
`;

const StyledAutocompleteMenu = styled(AutocompleteMenu)`
    width: 400px;
`

type CountryInfo = {
    name: string;
    code: string;
    iso: string;
};

type AskMobileNumberFormValues = {
    iso: string;
    countryCode: string;
    mobile: string;

    confirmationCode: string;
};

const ChangeMobileNumberScreen = ({ onCancel }: { onCancel?: () => void }) => {
    const dispatch: AppDispatch = useDispatch();
    const customerInformation = useSelector(selectCustomerInfo);
    const [step, setStep] = useState<'phoneNumber' | 'smsOtp'>('phoneNumber');
    const [entries, setEntries] = useState<Array<CountryInfo>>([]);
    const [selectedEntry, setSelectedEntry] = useState<CountryInfo>();
    const [smsId, setSmsId] = useState<string>('');
    const [httpErrorMessage, setHttpErrorMessage] = useState<string | undefined>(undefined);
    let formMobile: FormApi<AskMobileNumberFormValues, Partial<AskMobileNumberFormValues>>;

    const formatSmsCode = (value: string) => (value ? value.replace(/[^0-9]/, '') : '');

    useEffect(() => {
        if (customerInformation?.mobileInfo?.countryCode) {
            const country = countryCodes.find((c) => c.code === customerInformation?.mobileInfo?.countryCode);
            setSelectedEntry(country);
            setEntries(countryCodes);
        } else {
            const defaultItem = countryCodes.find((country) => country.code === '+41');
            setSelectedEntry(defaultItem);
            setEntries(countryCodes);
        }
    }, [customerInformation?.mobileInfo?.countryCode]);

    const {
        isOpen,
        getLabelProps,
        getMenuProps,
        getInputProps,
        highlightedIndex,
        getItemProps,
    } = useCombobox({
        items: entries,
        initialInputValue: customerInformation?.mobileInfo?.countryCode ?? '+41',
        itemToString: (item) => (item ? `${item.code}` : ''),
        onInputValueChange: ({ inputValue }) => {
            // when input is empty - reset selectedEntry & entries
            if (inputValue?.length === 0) {
                setSelectedEntry(undefined);
                setEntries(countryCodes);
            } else {
                const items: CountryInfo[] = countryCodes.filter(
                    (country) =>
                        country.name.toLowerCase().includes(inputValue?.toLowerCase() ?? '') ||
                        country.code.includes(inputValue ?? '')
                );
                setSelectedEntry(items.length === 1 ? items[0] : undefined);
                setEntries(items);
            }

            const mobileField = document.getElementById('mobile') as HTMLInputElement;
            const tmpValue = mobileField.value;

            setTimeout(() => {
                formMobile.change('mobile', '');
                formMobile.change('mobile', tmpValue);
            });
        },
        onSelectedItemChange: (item) => {
            const { selectedItem } = item;

            if (selectedItem) {
                setSelectedEntry(selectedItem);
            }
        },
    });

    const handleValidatePhoneNumber = (value: string) => {
        const countryCode = selectedEntry?.code ?? customerInformation?.mobileInfo?.countryCode;

        return PhoneNumberValidatorFn(
            `${countryCode} ${value}`.replace('-', ''),
            countryCode?.replace('-', ''),
            selectedEntry?.iso ?? customerInformation?.mobileInfo?.iso
        );
    };

    const sendAnotherCode = () => {
        userInfoService.resendNewSmsOtp({smsId}).then(() => {
        });
    };

    const handleFormStep = () => {
        if (step === 'phoneNumber') {
            if (!formMobile.getFieldState('mobile') || formMobile.getFieldState('mobile')?.error) {
                return;
            }
            let enteredNumber = formMobile.getFieldState('mobile')?.value;
            if (enteredNumber) {
                enteredNumber = sanitizePhoneNumber(enteredNumber);
            }
            userInfoService.sendSmsOtp({
                newMobilePhone: `${selectedEntry?.code}${enteredNumber}`,
            }).then((response) => {
                setSmsId(response.data.smsId);
                setStep('smsOtp');
            });
        } else if (step === 'smsOtp') {
            formMobile.submit();
        }
    };

    const handleSubmit = (values: any) => {
        setHttpErrorMessage(undefined);
        userInfoService.changeMobilePhoneNumber({
            otp: values.confirmationCode,
            smsId,
        }).then(() => {
            dispatch(
                update({
                    mobileInfo: {
                        iso: selectedEntry?.iso ?? '',
                        phoneNumber: sanitizePhoneNumber(values.mobile),
                        countryCode: selectedEntry?.code ?? '',
                    },
                })
            );
            dispatch(setLastUserInfoUpdate('mobile'));
        }).catch((error) => {
            setHttpErrorMessage(i18next.t(`shared.error.http.${error.response.data.debugMessage}`));
        });
    };

    return (
        <StyledCenterGrid>
            <GridItem size={10}>
                <GridItem size="full">
                    <StyledTitle>{i18next.t<string>('uam.changePhoneNumberScreen.intro')}</StyledTitle>
                    <Spacer space="delta" />
                </GridItem>
                <Spacer space="delta" />
                <Form
                    initialValues={{
                        countryCode: customerInformation?.mobileInfo?.iso ?? '+41',
                        mobile: customerInformation?.mobileInfo?.phoneNumber,
                    }}
                    onSubmit={handleSubmit}
                    render={({ handleSubmit, form }) => {
                        formMobile = form;
                        return (
                            <form onSubmit={handleSubmit}>
                                {/* Phone number section */}
                                <section style={{ display: step === 'phoneNumber' ? 'flex' : 'none' }}>
                                    <Grid>
                                        <GridItem size="full">
                                            <H2>{i18next.t<string>('uam.changePhoneNumberScreen.newPhoneNumberIntro')}</H2>
                                            <Spacer space="delta" />
                                        </GridItem>
                                        <GridItem size={4}>
                                                <Input
                                                    id="countryCode"
                                                    name="countryCode"
                                                    data-testid="countryCode"
                                                    label={i18next.t(
                                                        'uam.askMobileNumberScreen.countryNumberLabel',
                                                        'Vorwahl'
                                                    )}
                                                    labelProps={{ ...getLabelProps() }}
                                                    inputRightIcon={{
                                                        iconName: isOpen ? 'arrow_up' : 'arrow_down',
                                                    }}
                                                    {...getInputProps()}
                                                />

                                                <StyledAutocompleteMenu {...getMenuProps()} >
                                                    {isOpen &&
                                                        entries.map((item, index) => (
                                                            <AutocompleteMenuItem
                                                                key={`${item.code}_${index}`}
                                                                {...getItemProps({ item, index })}
                                                                isHighlighted={highlightedIndex === index}
                                                            >
                                                                {item.code} {item.name}
                                                            </AutocompleteMenuItem>
                                                        ))}
                                                </StyledAutocompleteMenu>
                                        </GridItem>

                                        <GridItemInput
                                            format={formatPhoneNumber}
                                            gridItemSize={8}
                                            inputName="mobile"
                                            label={i18next.t('uam.changePhoneNumberScreen.phoneNumberLabel')}
                                            validators={handleValidatePhoneNumber}
                                        />

                                        <GridItem size="full">
                                            <Spacer space="delta" />
                                        </GridItem>
                                    </Grid>
                                </section>
                                {/* End phone number section */}
                                {/* SMS OTP section */}
                                <section style={{ display: step === 'smsOtp' ? 'flex' : 'none' }}>
                                    <Grid>
                                        <GridItem size="full">
                                            <Box
                                                padding="regular"
                                                shadow="ctaBlueHover"
                                                borderColor="blue500"
                                                borderRadius={4}
                                                background="blue400"
                                            >
                                                <Grid>
                                                    <Icon iconName="check_outlined" style={{ marginTop: rem(4) }} />
                                                    <GridItem>
                                                        <strong>
                                                            {i18next.t<string>(
                                                                'uam.changePhoneNumberScreen.messageBox.title'
                                                            )}
                                                        </strong>{' '}
                                                        <br />
                                                        {i18next.t<string>(
                                                            'uam.changePhoneNumberScreen.messageBox.paragraph'
                                                        )}
                                                    </GridItem>
                                                </Grid>
                                            </Box>
                                            <Spacer space="delta" />
                                        </GridItem>

                                        <GridItem size="full">
                                            <H2>{i18next.t<string>('uam.changePhoneNumberScreen.codeIntro')}</H2>
                                            <Spacer space="delta" />
                                        </GridItem>
                                        <GridItemInput
                                            gridItemSize="full"
                                            label={i18next.t(`uam.changePhoneNumberScreen.codeLabel`)}
                                            inputName="confirmationCode"
                                            maxLength={6}
                                            format={formatSmsCode}
                                            validators={composeValidators(
                                                required(
                                                    i18next.t('uam.changePhoneNumberScreen.validations.codeRequiredError')
                                                ),
                                                minLength(
                                                    i18next.t(
                                                        'uam.changePhoneNumberScreen.validations.codeMinLengthMessage'
                                                    ),
                                                    6
                                                )
                                            )}
                                        />
                                        <GridItem size="full">
                                            <Spacer space="delta" />
                                            <LinkButton
                                                onClick={sendAnotherCode}
                                                text={i18next.t('uam.changePhoneNumberScreen.newCodeLink')}
                                                iconAfter="link_arrow"
                                                iconColor="secondary"
                                            />
                                            <Spacer space="delta" />
                                        </GridItem>
                                    </Grid>
                                </section>
                                {/* End SMS OTP section */}
                                <Grid>

                                    <StyledGridButtons size="full">
                                        <span style={{color: colors.red800, lineHeight: 3.5, marginRight: '8px'}}>{httpErrorMessage}</span>
                                        <Button
                                            type="button"
                                            buttonText={i18next.t('shared.formButton.cancel') ?? ''}
                                            data-testid={dataTestId('cancel-button')}
                                            outlined
                                            onClick={onCancel}
                                        />
                                        <Button
                                            type="button"
                                            style={{ marginLeft: '8px' }}
                                            buttonText={i18next.t('shared.formButton.save') ?? ''}
                                            data-testid={dataTestId('save-button')}
                                            variant="primary"
                                            onClick={handleFormStep}
                                        />
                                    </StyledGridButtons>
                                </Grid>
                            </form>
                        );
                    }}
                />
            </GridItem>
        </StyledCenterGrid>
    );
};

export default ChangeMobileNumberScreen;
