const ATUPRI_DOMAIN: string = 'atupri.ch';
const OPENSHIFT_PREFIX: string = 'ocp';

declare global {
	interface Window {
		__ENV__: EnvironmentType;
	}
}

export type EnvironmentType = {
	REACT_APP_STAGE: 'prod' | 'int' | 'test' | 'dev' | 'demo' | undefined;
	HOST: string | undefined;
};

const getOrigin = (): string => {
    return window.location ? window.location.hostname : ATUPRI_DOMAIN;
};

export const isInternal = () => {
    return getOrigin().includes(OPENSHIFT_PREFIX) || getEnvironment() === 'local';
};

export const getInternalUrl = () => {
    const environment = getEnvironment();
    const shortEnvironment = getShortEnvironment();
    const defaultServiceName = 'regcomp-api';
    switch (environment) {
        case 'local': {
            return formatOpenshiftUrl('d', defaultServiceName, '-d');
        }
        case 'demo': {
            return formatOpenshiftUrl('', 'myatupri-api-mock', '');
        }
        case 'prod':
            return formatOpenshiftUrl(shortEnvironment, defaultServiceName, '');
        default: {
            return formatOpenshiftUrl(shortEnvironment, defaultServiceName, `-${shortEnvironment}`);
        }
    }
};

export const getPublicUrl = () => {
    return formatPublicUrl(getEnvironment() === 'prod' ? '' : getShortEnvironment());
};

export const getEnvironment = () => {
	return window.__ENV__ ? window.__ENV__.REACT_APP_STAGE : 'local';
};

export const getShortEnvironment = () =>
    getEnvironment()?.charAt(0) ?? '';

const formatOpenshiftUrl = (prefix: string, service: string, subDomain: string) => {
    return `https://${prefix}${service}.ocp${subDomain}.${ATUPRI_DOMAIN}`;
};

const formatPublicUrl = (shortEnvironment: string) => {
    return `https://${shortEnvironment}registration-api.${ATUPRI_DOMAIN}`;
};
