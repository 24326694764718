/**
 * I18n configuration
 */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import { getEnvironment } from '../helper/environment';
import { logger } from '../helper';

const i18nConfig = {
	debug: getEnvironment() !== 'prod',
	fallbackLng: 'de',
	supportedLngs: ['de', 'fr', 'it'],
	preload: ['de', 'fr', 'it'],
	interpolation: {
		escapeValue: false,
	},
	backend: {
		loadPath: `/locales_regcomp/{{lng}}.json`,
	},
	detection: {
		// The order how the language gets detected. First entry has the highest precedence.
		order: ['querystring', 'localStorage', 'navigator'],
		// Store the selected language in the local storage
		caches: ['localStorage'],
	}
};

// Init i18n
i18n.use(initReactI18next) // passes i18n down to react-i18next
	.use(HttpBackend) // Load translations from HTTP Backend
	.use(languageDetector) // Automatically detect language
	.init(i18nConfig)
	.catch((err) => {
		logger('i18next', ['Failed to initialize i18n.', err], true);
	});
