import { getEnvironment, isInternal } from '../helper/environment';
import { refineURL } from '../helper/url';
import { NevisClientId } from './NevisClientId';

export const getCiamBaseUrl = () => {
    let shortEnv;
    switch (getEnvironment()) {
        case 'prod': {
            shortEnv = '';
            break;
        }
        case 'int': {
            shortEnv = 'i';
            break;
        }
        default: {
            shortEnv = 't';
        }
    }

    return `https://${shortEnv}login.atupri.ch/ciam`;
};

export const getRedirectUrl = () => {
    const environment = getEnvironment();
    const shortEnvironment = environment?.charAt(0) ?? '';

    switch (environment) {
        case 'local': {
            return 'http://localhost:3000/';
        }
        case 'test': {
            return 'https://taccount.atupri.ch';
        }
        case 'prod': {
            return 'https://account.atupri.ch';
        }
        default: {
            if (isInternal()) {
                return `https://${shortEnvironment}account.ocp-${shortEnvironment}.atupri.ch`;
            }
            return `https://${shortEnvironment}account.atupri.ch`;
        }
    }
};

export const getClientId = () => {
    const environment = getEnvironment();

    switch (environment) {
        case 'local': {
            return NevisClientId.LOCAL;
        }
        case 'dev': {
            return NevisClientId.DEV;
        }
        case 'test': {
            return NevisClientId.TEST;
        }
        case 'int': {
            return NevisClientId.INT;
        }
        case 'prod': {
            return NevisClientId.PROD;
        }
        default: {
            return '';
        }
    }
};

const oidcConfig = {
    authority: `${getCiamBaseUrl()}/oauth2/`,
    client_id: getClientId(),
    redirect_uri: `${getRedirectUrl()}`,
    scope: 'openid offline_access profile email',
    automaticSilentRenew: true,
    onSigninCallback: refineURL,
    revokeTokensOnSignout: true,
    metadata: {
        issuer: getCiamBaseUrl(),
        authorization_endpoint: `${getCiamBaseUrl()}/oauth2/auth/`,
        end_session_endpoint: `${getCiamBaseUrl()}/oauth2/auth/?logout&redirect_uri=${getRedirectUrl()}`,
        revocation_endpoint: `${getCiamBaseUrl()}/oauth2/revoke/`,
        token_endpoint: `${getCiamBaseUrl()}/oauth2/token/`,
        jwks_uri: `${getCiamBaseUrl()}/.well-known/jwks.json`,
    },
};

export default oidcConfig;
